import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";

const DxMultiSelect = (props) => {
    const { optionList, handleChangeOptions, options } = props;
    const [selectedOptions, setSelectedOptions] = useState(null);
    const handleChange = (e) => {
        setSelectedOptions(e.value);
        const output = e.value.map((item) => item.code).join(",");
        handleChangeOptions(output);
    };
    useEffect(() => {
        if (options) {
            const result = options.split(",").map(item => ({ name: item, code: item }));
            setSelectedOptions(result);
        }
    }, [options]);
    
    return (
        <MultiSelect
            value={selectedOptions}
            onChange={handleChange}
            options={optionList}
            optionLabel="name"
            placeholder="Select sale channels"
            maxSelectedLabels={3}
            className="w-full"
        />
    );
};

export default DxMultiSelect;
