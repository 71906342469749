import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import axios from "axios";
import { storeSearchByFields } from "../../actions/actions";
import { Toast } from 'primereact/toast';

const GrabFoodIntegration = ({ queryData, dispatch }) => {
    const toast = useRef(null);
    const { stores } = queryData;
    const [selectedStore, setSelectedStore] = useState({});
    const [storeList, setStoreList] = useState([]);
    const [storeId, setStoreId] = useState();

    useEffect(() => {
        const companyId = localStorage.getItem("company_id");
        dispatch(storeSearchByFields(`companyid=${companyId}`));
    }, [dispatch]);

    useEffect(() => {
        setStoreList(
            stores.map((store) => ({
                label: `(${store.storeid}) ${store.title}`,
                value: store.storeid,
            })),
        );
    }, [stores]);

    useEffect(() => {
        if (storeId?.length > 0) {
            fetchGrabNotification(storeId);
        }
    }, [storeId]);

    const getAccessToken = async () => {
        const data = JSON.stringify({
            client_id: "b8cafc5bc3894106a7638d5de79eaeaf",
            client_secret: "bFT8DjgbZpJQ7MQa",
            grant_type: "client_credentials",
            scope: "food.partner_api",
        });
    
        const config = {
            method: 'post',
            url: 'https://api.grab.com/grabid/v1/oauth2/token',
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        };
    
        try {
            const response = await axios.request(config);
            return response.data.access_token; // Assuming the token is returned under `access_token`
        } catch (error) {
            console.error('Error fetching access token:', error);
            throw new Error('Unable to fetch access token'); // Throw an error if the request fails
        }
    };
    
    
    const fetchGrabNotification = async (storeId) => {
        const BASE_URL = window?.config?.END_POINT;
        const authToken = localStorage.getItem('session');
        
        try {
            // Fetch the access token
            const token = await getAccessToken(); 
            console.log('Access Token:', token);
    
            const config = {
                method: "get",
                url: `${BASE_URL}/system/v1/store/tag/search/fields?storeid=${storeId}&tagtype=storeprops&taggroup=storeprops`,
                headers: {
                    Authorization: authToken,
                },
            };
    
            const response = await axios.request(config);
            if (response.status === 200) {
                const { additionalfields } = response.data[0] || {};
                const additionalConfigs = additionalfields?.additionalConfigs;
    
                if (additionalConfigs) {
                    try {
                        const { grabfood } = JSON.parse(additionalConfigs);
                        const { mapping_grabMerchantID } = grabfood;
                        console.log('Merchant ID:', mapping_grabMerchantID);
                    } catch (parseError) {
                        showToast('error', 'Configuration Error', 'Invalid GrabFood configuration data.');
                    }
                } else {
                    showToast('info', 'Info', 'GrabFood configuration is not configured.');
                }
            } else {
                showToast('info', 'Info', 'Failed to fetch GrabFood configuration.');
            }
        } catch (error) {
            console.error('Error fetching GrabFood notification:', error);
            showToast('error', 'Error', 'Unable to send GrabFood notification.');
        }
    };
    
    
    // Helper function to show toast messages
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail });
    };
    

    const handleChange = (e) => {
        const selectedId = e.value;
        setStoreId(selectedId);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="form_height">
            <Header title="Grab Food Notification" />

            <div className="frmDiv mx-3 mt-3">
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid">Select Store</label>
                        <Dropdown
                            className="w-100"
                            id="storeid"
                            name="storeid"
                            value={storeId}
                            onChange={handleChange}
                            options={storeList}
                            optionLabel="label"
                            placeholder="Select store"
                        />
                    </div>
                    <div className="field mb-2" style={{ display: "flex" }}>
                        <NotifyButton />
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

const mapStateToProps = (state) => ({
    queryData: state.queryData,
});

export default connect(mapStateToProps)(GrabFoodIntegration);

// Reusable Components

const Header = ({ title }) => (
    <div className="m-4">
        <h2 className="text-3xl font-normal" style={{ color: "#212121" }}>
            {title}
        </h2>
    </div>
);

const NotifyButton = () => (
    <div style={{ marginTop: "28px" }}>
        <Button label="Notify" icon="pi pi-bell" severity="success" outlined />
    </div>
);
