import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    createDeliveryArea,
    updateDeliveryArea,
    crudReset,
    getStoreById,
    deliverytypeConfig,
    deliverycountryConfig,
    productArticleReset,
} from "../../../actions/actions";
import StatusComp from "../../../components/StatusComp";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
import SimpleSelect from "../../../components/SimpleSelect";
import Filter from "../../../components/Filter";
import DeliveryMessage from "./DeliveryMessage";
import moment from "moment";
import {
    objtoarr,
    renderOptionsFilter,
    renderCouponOptionsFilter,
} from "../../../helpers/Util";
import DynamicTimePicker, {
    daylist,
} from "../../../components/DynamicTimePicker";

class DeliveryAreaForm extends React.PureComponent {
    deliveryperiod = [
        { title: "Minute", value: "MI" },
        { title: "Hours", value: "HH" },
        { title: "Day", value: "DD" },
    ];

    etaby = [
        { title: "Minute", value: "mi" },
        { title: "Hour", value: "hr" },
        { title: "Day", value: "day" },
    ];

    measureby = [
        { title: "Distance (meter)", value: "ME" },
        { title: "Distance (kilometer)", value: "KM" },
    ];

    priority = [
        { title: "Normal", value: "Normal" },
        { title: "Low", value: "Low" },
        { title: "Medium", value: "Medium" },
        { title: "High", value: "High" },
    ];

    customersegment = [{ title: "New Member", value: "New Member" }];
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            storeid: "",
            title: "",
            deliverytype: "",
            deliverytypevalue: "",
            activedays: [
                {
                    day: "",
                    activetimefrom: "",
                    activetimetill: "",
                    id: 0,
                },
            ],
            deliveryperiod: "",
            deliveryperiodvalue: "",
            deliverynearby: "",
            bulkyitem: "",
            supplierid: "",
            suppliergroup: "",
            deliveryprice: "",
            measureby: "",
            measurebyvaluemin: "",
            measurebyvaluemax: "",
            searchkeys: "",
            address: "",
            town: "",
            location: "",
            state: "",
            city: "",
            country: "",
            postalcode: "",
            contact: "",
            latitude: "",
            longitude: "",
            priority: "",
            customersegment: "",
            status: "Active",
            sortorder: 0,
            deliveryMessage: [{ field: "", value: "", id: 0 }],
            deliveryfocamt: "",
            etaby: "",
            etabymin: "",
            etabymax: "",
            tablecols: [
                { dataFieldId: "day", label: "Day", addRow: true },
                {
                    dataFieldId: "activetimefrom",
                    label: "ActiveTime From",
                    addRow: true,
                },
                {
                    dataFieldId: "activetimetill",
                    label: "ActiveTime Till",
                    addRow: true,
                },
                { dataFieldId: "AD", label: "" },
            ],
            // Errors
            deliverytypeError: "",
            townError: "",
            cityError: "",
            countryError: "",
            titleError: "",
            activeDayError: "",
            deliveryMessageError: "",
            postalCodeError: "",
            phoneError: "",
            timeerror: "",
        };
    }

    callFetch = () => {
        const { storeid } = this.state;
        this.props.dispatch(
            deliverycountryConfig({
                body: { status: "Active", propgroup: "Country", storeid },
            }),
        );
        this.updateFields();
    };

    componentWillMount = () => {
        this.props.dispatch(
            deliverytypeConfig({
                body: {
                    language: "en",
                    propgroup: "OPS",
                    propvalue: "Delivery",
                },
            }),
        );
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            if (rec) {
                const {
                    id,
                    title,
                    storeid,
                    deliverytype,
                    deliverytypevalue,
                    deliveryprice,
                    suppliergroup,
                    supplierid,
                    measureby,
                    measurebyvaluemax,
                    measurebyvaluemin,
                    country,
                    state,
                    city,
                    town,
                    building,
                    postalcode,
                    address,
                    latitude,
                    longitude,
                    searchkeys,
                    status,
                    sortorder,
                    etaby,
                    etabymin,
                    etabymax,
                    deliverymsg,
                    deliveryfocamt,
                    activedays,
                    // activetimefrom,
                    // activetimetill,
                    deliveryperiod,
                    deliveryperiodvalue,
                    deliverynearby,
                    bulkyitem,
                    priority,
                    customersegment,
                    orderbefore,
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                this.props.dispatch(
                    deliverycountryConfig({
                        body: {
                            status: "Active",
                            propgroup: "Country",
                            storeid,
                        },
                    }),
                );
                let defaultField = [{ field: "", value: "", id: 0 }];
                let defaultactiveday = [
                    {
                        day: "",
                        activetimefrom: "",
                        activetimetill: "",
                        id: 0,
                    },
                ];
                let activedaylist = this.getActiveDays(activedays);
                //let activefrom = this.formatTime(activetimefrom);
                //let activetill = this.formatTime(activetimetill);
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.title = title ? title : "";
                        draft.storeid = storeid ? storeid : "";
                        draft.status = status ? status : [];
                        draft.sortorder = sortorder ? sortorder : 0;
                        draft.activedays = activedays
                            ? activedaylist
                            : defaultactiveday;
                        //draft.activetimefrom = activetimefrom ? activefrom : 0;
                        //draft.activetimetill = activetimetill ? activetill : 0;
                        draft.deliverytype = deliverytype ? deliverytype : "";
                        draft.deliverytypevalue = deliverytypevalue
                            ? deliverytypevalue
                            : "";
                        draft.supplierid = supplierid ? supplierid : "";
                        draft.suppliergroup = suppliergroup
                            ? suppliergroup
                            : "";
                        draft.deliveryprice = deliveryprice ? deliveryprice : 0;
                        draft.measureby = measureby ? measureby : "";
                        draft.measurebyvaluemin = measurebyvaluemin
                            ? measurebyvaluemin
                            : 0;
                        draft.measurebyvaluemax = measurebyvaluemax
                            ? measurebyvaluemax
                            : 0;
                        draft.building = building ? building : "";
                        draft.address = address ? address : "";
                        draft.city = city ? city : "";
                        draft.country = country ? country : "";
                        draft.state = state ? state : "";
                        draft.town = town ? town : "";
                        draft.postalcode = postalcode ? postalcode : "";
                        draft.customersegment = customersegment
                            ? customersegment
                            : "";
                        draft.priority = priority ? priority : "";
                        draft.latitude = latitude ? latitude : "";
                        draft.longitude = longitude ? longitude : "";
                        draft.searchkeys = searchkeys ? searchkeys : "";
                        draft.deliveryMessage =
                            typeof deliverymsg !== "undefined" &&
                            Object.keys(deliverymsg).length !== 0
                                ? objtoarr(deliverymsg)
                                : defaultField;
                        draft.etabymax = etabymax ? etabymax : 0;
                        draft.etabymin = etabymin ? etabymin : 0;
                        draft.etaby = etaby ? etaby : "";
                        draft.deliveryfocamt = deliveryfocamt
                            ? deliveryfocamt
                            : 0;
                        draft.deliveryperiod = deliveryperiod
                            ? deliveryperiod
                            : "";
                        draft.deliveryperiodvalue = deliveryperiodvalue
                            ? deliveryperiodvalue
                            : "";
                        draft.deliverynearby = deliverynearby
                            ? deliverynearby
                            : "";
                        draft.bulkyitem = bulkyitem ? bulkyitem : "";
                        draft.orderbefore = rec.hasOwnProperty("orderbefore")
                            ? orderbefore
                            : 0;
                        draft.lastupdated = rec.lastupdated;
                    }),
                );
            }
        }
    };

    getActiveDays = (list) => {
        if (
            list !== null &&
            typeof list !== "undefined" &&
            list.length &&
            list.length > 0
        ) {
            return list.map((item, id) => ({ ...item, id }));
        } else {
            return [{ day: "", activetimefrom: "", activetimetill: "", id: 0 }];
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errorMessage } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errorMessage && !isSuccess) {
            return (
                <div className="alert alert-danger grid mt-2 mx-3" role="alert">
                    {errorMessage}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger grid mt-2 mx-3" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    formatTime = (str) => {
        if (
            str !== "" &&
            str !== 0 &&
            typeof str !== "undefined" &&
            str !== null
        ) {
            if (str.toString().includes(":")) {
                return str.replace(/:/g, "");
            } else {
                let str1 =
                    str.toString().length === 1
                        ? "000" + str.toString()
                        : str.toString().length === 2
                          ? "00" + str.toString()
                          : str.toString().length === 3
                            ? "0" + str.toString()
                            : str.toString();
                return str1.replace(/(\d{2})(\d{2})/g, "$1:$2");
            }
        } else {
            return "";
        }
    };

    _remapActiveDays = (activelist) => {
        const findNoDayMatched = daylist.filter((day) => {
            const match = activelist.find(
                (activeDay) => activeDay.day === day.value,
            );
            if (match) {
                return false;
            } else {
                return true;
            }
        });
        for (let loop = 0; loop < findNoDayMatched.length; loop++) {
            activelist.push({
                day: findNoDayMatched[loop].value,
                activetimefrom: "00:00",
                activetimetill: "00:01",
            });
        }
        return activelist;
    };

    getCreatePayload = () => {
        const {
            storeid,
            title,
            deliverytype,
            deliverytypevalue,
            suppliergroup,
            supplierid,
            deliveryprice,
            measureby,
            measurebyvaluemax,
            measurebyvaluemin,
            country,
            state,
            city,
            town,
            building,
            postalcode,
            address,
            status,
            sortorder,
            latitude,
            longitude,
            searchkeys,
            deliveryMessage,
            etaby,
            etabymin,
            etabymax,
            deliveryfocamt,
            activedays,
            //activetimetill,
            //activetimefrom,
            deliveryperiod,
            deliveryperiodvalue,
            deliverynearby,
            bulkyitem,
            priority,
            customersegment,
            orderbefore,
        } = this.state;
        let addfield = deliveryMessage.reduce(function (acc, item) {
            if (item.field.trim() !== "" && item.value.trim() !== "") {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {});
        //let timefrom = this.formatTime(activetimefrom);
        //let timetill = this.formatTime(activetimetill);
        let activelist = activedays
            .map((e) => ({
                day: e.day,
                activetimefrom: e.activetimefrom,
                activetimetill: e.activetimetill,
            }))
            .filter((e) => e.day !== "");
        activelist = this._remapActiveDays(activelist);

        let body = {
            storeid,
            title,
            deliverytype,
            deliverytypevalue,
            suppliergroup,
            supplierid,
            activedays: activelist,
            //activetimefrom: parseInt(timefrom) || 0,
            //activetimetill: parseInt(timetill) || 0,
            deliveryprice: parseInt(deliveryprice) || 0,
            measureby,
            measurebyvaluemax: parseInt(measurebyvaluemax) || "",
            measurebyvaluemin: parseInt(measurebyvaluemin) || "",
            country,
            state,
            city,
            building,
            town,
            postalcode,
            address,
            status,
            sortorder: sortorder || 0,
            latitude,
            longitude,
            searchkeys,
            deliverymsg: addfield,
            etaby,
            etabymin: parseInt(etabymin) || "",
            etabymax: parseInt(etabymax) || "",
            deliveryfocamt: parseInt(deliveryfocamt) || "",
            deliveryperiod,
            deliveryperiodvalue,
            deliverynearby,
            bulkyitem,
            priority,
            customersegment,
            orderbefore,
        };
        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body,
        };
        //console.log(payload);
        return payload;
    };

    _createDA = () => {
        if (this.validateForm()) {
            if (this.state.isEdit === true) {
                this.props.dispatch(
                    updateDeliveryArea(this.getCreatePayload(), this.state.id),
                );
            } else {
                this.props.dispatch(
                    createDeliveryArea(this.getCreatePayload()),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Please clear all the errors to continue !!!",
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            deliverytypeError: "",
            townError: "",
            cityError: "",
            countryError: "",
            titleError: "",
            activeDayError: "",
            deliveryMessageError: "",
            formError: false,
            formErrorMsg: "",
        });
    };

    checkduplicateList = (obj) => {
        let ids = {};
        let dups = [];

        obj.forEach((val) => {
            if (ids[val.day]) {
                dups.push(val);
            } else {
                ids[val.day] = true;
            }
        });
        return dups;
    };

    _checkOnlySingleDay = (values) => {
        let seen = new Set();
        var hasDuplicates = values.some(function (currentObject) {
            return seen.size === seen.add(currentObject.day).size;
        });

        return !hasDuplicates;
    };

    checkDayList = (obj) => {
        const hasAll = obj.some((o) => o.day === "");
        return (obj.length === 1 && hasAll) || !hasAll;
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { title, activedays, deliverytype, town, city, country } =
            this.state;
        let valid = true;
        if (title.trim() === "") {
            this.setState({
                titleError: "Please enter a title",
            });
            valid = false;
        }
        if (deliverytype.trim() === "" || deliverytype.trim() === "0") {
            this.setState({
                deliverytypeError: "Please fill in Delivery",
            });
            valid = false;
        }
        if (town.trim() === "") {
            this.setState({
                townError: "Town is mandatory",
            });
            valid = false;
        }
        if (city.trim() === "" || city.trim() === "0") {
            this.setState({
                cityError: "City is mandatory",
            });
            valid = false;
        }
        if (country.trim() === "" || country.trim() === "-1") {
            this.setState({
                countryError: "Country is mandatory",
            });
            valid = false;
        }
        if (activedays.length > 0) {
            // check activetime is empty
            let checkempty = activedays.filter(
                (e) =>
                    (e.activetimefrom === "" && e.activetimetill === "") ||
                    (e.activetimefrom !== "" && e.activetimetill === "") ||
                    (e.activetimefrom === "" && e.activetimetill !== "") ||
                    e.activetimefrom === "Invalid date" ||
                    e.activetimetill === "Invalid date",
            );
            if (checkempty.length > 0) {
                this.setState({
                    activeDayError: "Please enter activetime",
                });
                valid = false;
            }

            let checkduplicates = this.checkduplicateList(activedays);
            if (checkduplicates.length > 0) {
                this.setState({
                    activeDayError: "Duplicate Day!",
                });
                valid = false;
            }

            let checktimeintervals = activedays.map((e) => {
                if (
                    moment(e.activetimefrom, "HH:mm").isValid() &&
                    moment(e.activetimetill, "HH:mm").isValid()
                ) {
                    return moment(e.activetimefrom, "HH:mm").isBefore(
                        moment(e.activetimetill, "HH:mm"),
                    );
                }
            });
            if (checktimeintervals.includes(false)) {
                this.setState({
                    activeDayError: "Incorrect time intervals",
                });
                valid = false;
            }

            let checkday = this.checkDayList(activedays);
            if (checkday == false) {
                this.setState({
                    activeDayError: "Empty date",
                });
                valid = false;
            }

            let checkSingleDay = this._checkOnlySingleDay(activedays);
            if (checkSingleDay == false) {
                this.setState({
                    activeDayError: "Duplicate dates are not allowed.",
                });
                valid = false;
            }
        } else {
            this.setState({
                activeDayError: "Active days is mandatory",
            });
        }

        if (this.state.deliveryMessage) {
            for (
                let loop = 0;
                loop < this.state.deliveryMessage.length;
                loop++
            ) {
                const dlvMsg = this.state.deliveryMessage[loop];
                if (dlvMsg.value && dlvMsg.value.length > 499) {
                    this.setState({
                        deliveryMessageError:
                            "Delivery message must be less than 500 characters.",
                    });
                    valid = false;
                    break;
                }
            }
        }

        return valid;
    };
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createDA}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createDA}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/delivery-area"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, () => {
            this.callFetch();
        });
    };

    updateFields = () => {
        const { dataByCountry } = this.props.queryData;
        const { storeid } = this.state;
        if (dataByCountry.length > 0) {
            let getbystore = dataByCountry.filter((e) => e.storeid === storeid);
            if (getbystore.length > 0) {
                this.setState({
                    country: getbystore[0].country,
                });
            }
        }
    };

    componentWillReceiveProps = () => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid
                    ? this.setState({ storelabel: storeArray.title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
        }
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.activedays.length;
        if (rowLength !== 1) {
            const updateValues = this.state.activedays.filter(
                (value) => rowId !== value.id,
            );
            this.setState({ activedays: updateValues });
        } else {
            this.setState({
                activedays: [
                    { day: "", activetimefrom: "", activetimetill: "", id: 0 },
                ],
            });
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.activedays.length;
        let lastele = this.state.activedays[rowLength - 1];
        const item = {
            day: "",
            activetimefrom: "",
            activetimetill: "",
            id: lastele.id + 1,
        };
        this.setState(
            {
                activedays: [...this.state.activedays, item],
            },
            () => {
                console.log("Rows After Add", this.state.activedays);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField !== "activetimefrom" || "activetimetill"
                    ? event
                    : moment(event, "HH:mm"),
        };
        this.setState(
            produce((draft) => {
                if (draft.activedays.length === 0) {
                    draft.activedays.push(obj);
                } else {
                    let found = false;
                    draft.activedays.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.activedays.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    _renderMainFormDetails = () => {
        const { deliverytypeList } = this.props.queryData;
        let dlist = this.getObjectList(deliverytypeList);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        />
                        <div className="invalid-feedback">
                            {this.state.tenantCodeError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.titleError}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="deliverytype"
                            className="floatLeft required"
                        >
                            Delivery Type
                        </label>
                        <SimpleSelect
                            objArray={dlist}
                            callbackChangeFilter={(value) =>
                                this.setState({ deliverytype: value })
                            }
                            selectedOption={this.state.deliverytype}
                            id="deliverytype"
                        />
                        <div className="invalid-feedback">
                            {this.state.deliverytypeError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="deliverytypevalue"
                            className="floatLeft"
                        >
                            Delivery Type Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="deliverytypevalue"
                            value={this.state.deliverytypevalue}
                            onChange={(event) =>
                                this.setState({
                                    deliverytypevalue: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="supgroup" className="floatLeft">
                            Supplier Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="supgroup"
                            value={this.state.suppliergroup}
                            onChange={(event) =>
                                this.setState({
                                    suppliergroup: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="supid" className="floatLeft">
                            Supplier ID
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="supid"
                            value={this.state.supplierid}
                            onChange={(event) =>
                                this.setState({
                                    supplierid: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="priority" className="floatLeft">
                            Priority
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.priority)}
                            selectedOption={this.state.priority}
                            callbackChangeFilter={(event) => {
                                this.setState({ priority: event.target.value });
                            }}
                            id="priority"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="customersegment" className="floatLeft">
                            Customer Segment
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(
                                this.customersegment,
                            )}
                            selectedOption={this.state.customersegment}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    customersegment: event.target.value,
                                });
                            }}
                            id="customersegment"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _preventNegative = (value) => {
        if (value === "") {
            return "";
        }
        try {
            const _value = parseInt(value, 10);
            return _value > 0 ? _value : 0;
        } catch (err) {
            return 0;
        }
    };

    _renderOtherInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="deliveryprice" className="floatLeft">
                            Delivery Price
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="deliveryprice"
                            value={this.state.deliveryprice}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    deliveryprice: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="measureby" className="floatLeft">
                            Measure By
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.measureby)}
                            selectedOption={this.state.measureby}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    measureby: event.target.value,
                                });
                            }}
                            id="measureby"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="measurebyvaluemin"
                            className="floatLeft"
                        >
                            Minimum Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="measurebyvaluemin"
                            min="0"
                            value={this.state.measurebyvaluemin}
                            onChange={(event) =>
                                this.setState({
                                    measurebyvaluemin: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="measurebyvaluemax"
                            className="floatLeft"
                        >
                            Maximum Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="measurebyvaluemax"
                            min="0"
                            value={this.state.measurebyvaluemax}
                            onChange={(event) =>
                                this.setState({
                                    measurebyvaluemax: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    getObjectList = (myObj) => {
        let map = {};
        myObj.forEach((e) => {
            let details = e.additionalfields;
            Object.keys(details).forEach((key) => {
                var value = details[key];
                if (Array.isArray(value) && value.length > 0) {
                    map[key] = value[0];
                } else {
                    map[key] = value;
                }
            });
        });
        return map;
    };

    handleDeliveryNearby = (value) => {
        if (value == "0") {
            this.setState({ deliverynearby: "" });
        } else {
            this.setState({ deliverynearby: value });
        }
    };

    handleBulkyItem = (value) => {
        if (value == "0") {
            this.setState({ bulkyitem: "" });
        } else {
            this.setState({ bulkyitem: value });
        }
    };

    _renderContactInformation = () => {
        const { deliverycountryList } = this.props.queryData;
        let cnlist = deliverycountryList.map((e) => ({
            title: e.propvalue,
            value: e.propvalue,
        }));
        let citylist = Object.assign(
            {},
            ...deliverycountryList.map((e) => e.additionalfields),
        );
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="address" className="floatLeft">
                            Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={this.state.address}
                            onChange={(event) =>
                                this.setState({ address: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="location" className="floatLeft">
                            Building/Block
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="building"
                            value={this.state.building}
                            onChange={(event) =>
                                this.setState({ building: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="town" className="floatLeft required">
                            Town
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="town"
                            value={this.state.town}
                            onChange={(event) =>
                                this.setState({ town: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.townError}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="country" className="floatLeft required">
                            Country
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(cnlist)}
                            selectedOption={this.state.country}
                            callbackChangeFilter={(event) => {
                                this.setState({ country: event.target.value });
                            }}
                            id="country"
                        />
                        <div className="invalid-feedback">
                            {this.state.countryError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="city" className="floatLeft required">
                            City
                        </label>
                        <SimpleSelect
                            objArray={citylist}
                            callbackChangeFilter={(value) =>
                                this.setState({ city: value })
                            }
                            selectedOption={this.state.city}
                            id="city"
                        />
                        <div className="invalid-feedback">
                            {this.state.cityError}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="state" className="floatLeft">
                            State
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="state"
                            value={this.state.state}
                            onChange={(event) =>
                                this.setState({ state: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="postalcode" className="floatLeft">
                            Postal Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="postalcode"
                            value={this.state.postalcode}
                            onChange={(event) =>
                                this.setState({
                                    postalcode: event.target.value.replace(
                                        /[^0-9]/g,
                                        "",
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderEstimatedInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="deliveryfocamt" className="floatLeft">
                            Delivery Amount (FOC)
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="deliveryfocamt"
                            value={this.state.deliveryfocamt}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    deliveryfocamt: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="etaby" className="floatLeft">
                            Estimated Time of Order Arrival By
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.etaby)}
                            selectedOption={this.state.etaby}
                            callbackChangeFilter={(event) => {
                                this.setState({ etaby: event.target.value });
                            }}
                            id="etaby"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="etabymin" className="floatLeft">
                            Minimum Estimated Time
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="etabymin"
                            value={this.state.etabymin}
                            onChange={(event) =>
                                this.setState({
                                    etabymin: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="etabymax" className="floatLeft">
                            Maximum Estimated Time
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="etabymax"
                            value={this.state.etabymax}
                            onChange={(event) =>
                                this.setState({
                                    etabymax: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderActiveInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTimePicker
                            columns={this.state.tablecols}
                            rows={this.state.activedays}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                    <div className="invalid-feedback">
                        {this.state.activeDayError}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="deliveryperiod" className="floatLeft">
                            Delivery Period
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderCouponOptionsFilter(
                                this.deliveryperiod,
                            )}
                            selectedOption={this.state.deliveryperiod}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    deliveryperiod: event.target.value,
                                });
                            }}
                            id="deliveryperiod"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="deliveryperiodvalue"
                            className="floatLeft"
                        >
                            Delivery Period Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="deliveryperiodvalue"
                            value={this.state.deliveryperiodvalue}
                            onChange={(event) =>
                                this.setState({
                                    deliveryperiodvalue: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-12 col-lg-6">
                        <label htmlFor="deliverynearby " className="floatLeft">
                            Delivery NearBy
                        </label>
                        <SimpleSelect
                            objArray={{ Y: "Yes", N: "No" }}
                            callbackChangeFilter={(value) =>
                                this.handleDeliveryNearby(value)
                            }
                            selectedOption={this.state.deliverynearby}
                            id="deliverynearby"
                        />
                    </div>
                    <div className="form-group col-sm-12 col-lg-6">
                        <label htmlFor="bulkyitem" className="floatLeft">
                            Bulky Item
                        </label>
                        <SimpleSelect
                            objArray={{ Y: "Yes", N: "No" }}
                            callbackChangeFilter={(value) =>
                                this.handleBulkyItem(value)
                            }
                            selectedOption={this.state.bulkyitem}
                            id="bulkyitem"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="orderbefore"
                            className="floatLeft required"
                        >
                            Order Before
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="orderbefore"
                            min="0"
                            value={this.state.orderbefore}
                            onChange={(event) =>
                                this.setState({
                                    orderbefore: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderDataInfo = () => {
        return (
            <div className="row">
                <div className="form-group col-lg-12 col-sm-12">
                    <label htmlFor="latitude" className="floatLeft">
                        Last Updated Date
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastupdated"
                        disabled={true}
                        value={this.state.lastupdated}
                    />
                </div>
            </div>
        );
    };

    _renderGeneralInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="latitude" className="floatLeft">
                            Latitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="latitude"
                            value={this.state.latitude}
                            onChange={(event) =>
                                this.setState({ latitude: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="longitude" className="floatLeft">
                            Longitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="longitude"
                            value={this.state.longitude}
                            onChange={(event) =>
                                this.setState({ longitude: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="searchkeys" className="floatLeft">
                            Search Keys
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="searchkeys"
                            value={this.state.searchkeys}
                            onChange={(event) =>
                                this.setState({
                                    searchkeys: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Sort Order
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    sortorder: this._preventNegative(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    setDeliveryProps = (delv) => {
        this.setState({ deliveryMessage: delv });
    };

    _renderDeliveryMessage = () => {
        const { deliveryMessage } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="deliverymsg" className="floatLeft">
                            Delivery Message
                        </label>
                        <DeliveryMessage
                            delivery={deliveryMessage}
                            setDeliveryProps={this.setDeliveryProps}
                        ></DeliveryMessage>
                    </div>
                </div>
                <div className="invalid-feedback">
                    {this.state.deliveryMessageError}
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="grid px-3">
                    <div className="col-9">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderMainFormDetails()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderOtherInformation()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderEstimatedInformation()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderActiveInformation()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderDeliveryMessage()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderContactInformation()}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mb-4">
                            {this._renderStatusDetails()}
                        </div>
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mb-4">
                            {this._renderDetails()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mb-4"
                        >
                            {this._renderGeneralInformation()}
                        </div>
                        {this._isEditMode() && (
                            <div
                                className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                                style={{ marginTop: "2em" }}
                            >
                                {this._renderDataInfo()}
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(productArticleReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode() === true
                                    ? "Edit Delivery Area"
                                    : "Add Delivery Area"}
                            </h2>
                        </div>
                    </div>
                    {this._renderErrorMessage()}
                    {this._renderFormArea()}
                    <div className="btn-container form-button grid fixed w-full py-2 justify-content-center">
                        {this._renderMainButton()}
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/delivery-area"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode() === true
                                    ? "Delivery Area updated successfully"
                                    : "Delivery Area created successfully"}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DeliveryAreaForm);
