import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { produce } from 'immer';
import ReactSwitch from 'react-switch';
import { RadioButton } from 'primereact/radiobutton';
import 'rc-time-picker/assets/index.css';
import {
    crudReset,
    updateStoreTag,
    createStoreTag,
    queryPropertyConfigByFields,
    storeTagsSearchByFields,
} from '../../../../actions/actions';

import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import {
    SEARCH_PROP_CONFIG_TENDERTAGTYPES,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
    TENDERTAGTYPES,
    TENDER_GROUP_PTYPES,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE
} from '../../../../constants/constants';
import { formatLanguageArray } from '../../../../helpers/Util';
import SimpleImageInput from '../../../../components/SimpleImageInput';

class AddStoreTenders extends React.PureComponent {
    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'InActive', id: 'InActive' },
    ];

    tagTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Store Tender', id: 'tender' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            title: '',
            taggroup: 'storeprops',
            tagtype: 'tender',
            tenderTagTypeOptions: {},
            tagtypevalue: '',
            tendergroup: '',
            storedevices: [],
            // deviceid: '',

            status: 'New',
            sortorder: 1,

            storelabel: '',
            // Errors
            titleerror: '',
            tenderdescerror: '',
            storeiderror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            returntypeerror: '',
            tendergrouperror: '',

            tender: false,
            tenderdesc: '',
            opencashdrawer: false,
            loan: false,
            pickup: false,
            returntender: false,
            voidtender: false,
            minamount: 0,
            maxamount: 0,
            tendercount: 0,
            returntype: '',
            autopickup: false,
            tenderimg: '',
            tenderinstruction: '',
            tenderanimationimg: '',
            storecashinout: false,
            cashcount: false,
            mgrintervention: false,
            offlinetender: false,
            enableoffline: false,
            collectiontender: false,
            //store default props
            autolocktimeout: 0,
            paymenttimeout: 0,
            quicklookupcatcode: '',
            productplaceholderimg: '',
            promotionprefix: '',
            storeTenderGroupOptions: []
        };
    }

    handleTenderSwitch = (tender) => {
        this.setState({ tender });
    };

    handleLoanSwitch = (loan) => {
        this.setState({ loan });
    };

    handlePickupSwitch = (pickup) => {
        this.setState({
            pickup: pickup.target.value === 'Pickup',
            autopickup: pickup.target.value === 'AutoPickup',
        });
    };

    handleAutoPickupSwitch = (autopickup) => {
        this.setState(
            {
                autopickup: autopickup.target.value === 'AutoPickup',
                pickup: autopickup.target.value === 'Pickup',
            },
            () => {
                if (this.state.autopickup) {
                    //reset
                    this.setState({
                        loan: false,
                        cashcount: false,
                        storecashinout: false,
                    });
                }
            }
        );
    };

    handleReturnTenderSwitch = (returntender) => {
        this.setState({ returntender });
    };

    handleVoidTenderSwitch = (voidtender) => {
        this.setState({ voidtender });
    };

    handleOpenCashdrawerSwitch = (opencashdrawer) => {
        this.setState({ opencashdrawer });
    };

    handleReturnTypeSelection = (e) => {
        this.setState({ returntype: e.target.value });
    };

    handleStoreCashInOutSwitch = (storecashinout) => {
        this.setState({ storecashinout });
    };

    handleOfflineTenderSwitch = (offlinetender) => {
        this.setState({ offlinetender });
    };

    handleMgrInterventionSwitch = (mgrintervention) => {
        this.setState({ mgrintervention });
    };

    handleCashCountSwitch = (cashcount) => {
        this.setState({ cashcount });
    };

    handleEnableOfflineSwitch = (enableoffline) => {
        this.setState({ enableoffline });
    };

    getAdditionalFields = () => {
        if (this.state.tagtype === 'tender') {
            return {
                tenderdesc: this.state.tenderdesc,
                tender: this.state.tender ? 'Y' : 'N',
                voidtender: this.state.voidtender ? 'Y' : 'N',
                loan: this.state.loan ? 'Y' : 'N',
                pickup: this.state.pickup ? 'Y' : 'N',
                autopickup: this.state.autopickup ? 'Y' : 'N',
                opencashdrawer: this.state.opencashdrawer ? 'Y' : 'N',
                returntender: this.state.returntender ? 'Y' : 'N',
                returntype: this.state.returntype,
                minamount: this.state.minamount,
                maxamount: this.state.maxamount,
                tendercount: this.state.tendercount,
                tenderimg: this.state.tenderimg,
                tenderinstruction: this.state.tenderinstruction,
                tenderanimationimg: this.state.tenderanimationimg,
                tendergroup: this.state.tendergroup,
                offlinetender: this.state.offlinetender ? 'Y' : 'N',
                cashcount: this.state.cashcount ? 'Y' : 'N',
                mgrintervention: this.state.mgrintervention ? 'Y' : 'N',
                storecashinout: this.state.storecashinout ? 'Y' : 'N',
                enableoffline: this.state.enableoffline ? 'Y' : 'N',
                collectiontender: this.state.collectiontender ? 'Y' : 'N',
                // 'deviceid': this.state.deviceid
            };
        } else if (this.state.tagtype === 'storeprops') {
            return {
                autolocktimeout: this.state.autolocktimeout,
                paymenttimeout: this.state.paymenttimeout,
                quicklookupcatcode: this.state.quicklookupcatcode,
                productplaceholderimg: this.state.productplaceholderimg,
                promotionprefix: this.state.promotionprefix,
            };
        } else {
            return null;
        }
    };

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
        } = this.state;

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
            additionalfields: this.getAdditionalFields(),
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    _saveStoreTender = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreTag(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createStoreTag(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tenderdescerror: '',
            tendergrouperror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            storeid,
            taggroup,
            tagtype,
            tagtypevalue,
            returntender,
            returntype,
            tenderdesc,
            tendergroup,
            tagtypevalueerror,
        } = this.state;

        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'Please enter storeid' });
            valid = false;
        }
        if (taggroup.trim() === '') {
            this.setState({ taggrouperror: 'Please select the tag group' });
            valid = false;
        }

        if (tagtype.trim().length <= 0) {
            this.setState({ tagtypeerror: 'Please select tag type' });
            valid = false;
        }

        if (tagtype.trim() === 'tender') {
            console.log('checking', tagtypevalue, tagtypevalue.trim(), tagtypevalue.trim().length)
            if (tagtypevalue.trim().length <= 0) {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            } else if (tagtypevalue.trim() === 'Select') {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            }

            if (tenderdesc.trim().length <= 0) {
                this.setState({
                    tenderdescerror: 'Please enter the tender description',
                });
            }
            if (tendergroup.trim().length <= 0) {
                this.setState({ tendergrouperror: 'Please select tag group' });
            }
        }

        if (tagtypevalueerror.trim() !== '') {
            valid = false;
        }

        if (returntender) {
            if (returntype === '') {
                this.setState({ returntypeerror: 'Please select return type' });
                valid = false;
            }
        }

        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger w-100 mx-4" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger w-100 mx-4" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/storetenders"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveStoreTender(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveStoreTender(e)}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/storetenders"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _renderDoubleColSubSecDivider = () => {
        return <div className="doubleCol-subsec-divider"></div>;
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    _renderBasicDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Basic Information</div>
                <div className="grid">
                    <div className="col-6">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="col-6">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <label
                            htmlFor="tagtypevalue"
                            className="col-form-label "
                        >
                            Tag Type Value
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                disabled={true}
                            />
                        ) : this.state.tagtype === 'tender' &&
                          !(this._isEditMode() || this._isViewMode()) ? (
                            <StatusOptionComp
                                statusoption={formatLanguageArray(
                                    this.state.tenderTagTypeOptions,
                                    true
                                )}
                                selectedOption={this.state.tagtypevalue}
                                callbackChangeFilter={(value) => {
                                    this.setState(
                                        { tagtypevalue: value },
                                        () => {
                                            this.checkTenderForStore();
                                        }
                                    );
                                }}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                onChange={(event) =>
                                    this.setState({
                                        tagtypevalue: event.target.value,
                                    })
                                }
                                disabled={
                                    this._isViewMode() ||
                                    (this._isEditMode() &&
                                        this.state.tagtype === 'tender')
                                }
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.tagtypevalueerror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderTenderSettings = () => {
        if (this.state.tagtype === 'tender') {
            return (
                <React.Fragment>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="grid">
                        <div className="col-6">
                            <label
                                htmlFor="tenderdesc"
                                className="floatLeft required"
                            >
                                Description
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.tenderdesc}
                                onChange={(event) =>
                                    this.setState({
                                        tenderdesc: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.tenderdescerror}
                            </div>
                        </div>
                        <div className="col-6">
                            <label
                                htmlFor="tendergroup"
                                className="floatLeft required"
                            >
                                Tender Group
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    disabled={true}
                                />
                            ) : this.state.tagtype === 'tender' &&
                              !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.state.storeTenderGroupOptions}
                                    selectedOption={this.state.tendergroup}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ tendergroup: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    onChange={(event) =>
                                        this.setState({
                                            tendergroup: event.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() ||
                                        (this._isEditMode() &&
                                            this.state.tagtype === 'tender')
                                    }
                                />
                            )}
                            <div className="invalid-feedback">
                                {this.state.tendergrouperror}
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Sales Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.tender}
                                onChange={this.handleTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Open Cash Drawer
                            </label>
                            <ReactSwitch
                                checked={this.state.opencashdrawer}
                                onChange={this.handleOpenCashdrawerSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Void Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.voidtender}
                                onChange={this.handleVoidTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Return Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.returntender}
                                onChange={this.handleReturnTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        {this.state.returntender && (
                            <div className="col-3">
                                <label
                                    htmlFor="returntype"
                                    className="switch-label required mb-2"
                                >
                                    Return Type
                                </label>
                                <div>
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="returntype"
                                            name="returntype"
                                            value="cash"
                                            onChange={(e) =>
                                                this.setState({
                                                    returntype: e.value,
                                                })
                                            }
                                            checked={
                                                this.state.returntype === 'cash'
                                            }
                                        />
                                        <label htmlFor="returntype">Cash</label>
                                    </div>
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="returntype"
                                            name="returntype"
                                            value="ccv"
                                            onChange={(e) =>
                                                this.setState({
                                                    returntype: e.value,
                                                })
                                            }
                                            checked={
                                                this.state.returntype === 'ccv'
                                            }
                                        />
                                        <label htmlFor="returntype">CCV</label>
                                    </div>
                                    <div className="invalid-feedback mb-2">
                                        {this.state.returntypeerror}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="grid">
                        <div className="form-group col-3">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Manager Intervention
                            </label>
                            <ReactSwitch
                                checked={this.state.mgrintervention}
                                onChange={this.handleMgrInterventionSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Offline Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.offlinetender}
                                onChange={() =>
                                    this.setState({
                                        offlinetender:
                                            !this.state.offlinetender,
                                    })
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Collection Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.collectiontender}
                                onChange={() =>
                                    this.setState({
                                        collectiontender:
                                            !this.state.collectiontender,
                                    })
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Enable Offline
                            </label>
                            <ReactSwitch
                                checked={this.state.enableoffline}
                                onChange={this.handleEnableOfflineSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Auto Pickup
                            </label>
                            <ReactSwitch
                                checked={this.state.autopickup}
                                onChange={() =>
                                    this.setState({
                                        autopickup: !this.state.autopickup,
                                        pickup: !!this.state.autopickup,
                                    })
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    {!this.state.autopickup && (
                        <>
                            <div className="grid">
                                <div className="form-group col-3">
                                    <label
                                        htmlFor="validfrom"
                                        className="floatLeft switch-label"
                                    >
                                        Loan
                                    </label>
                                    <ReactSwitch
                                        checked={this.state.loan}
                                        onChange={this.handleLoanSwitch}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="discount-tax-switch"
                                        disabled={this._isViewMode()}
                                    />
                                </div>

                                <div className="form-group col-3">
                                    <label
                                        htmlFor="validfrom"
                                        className="floatLeft switch-label"
                                    >
                                        Cash Count
                                    </label>
                                    <ReactSwitch
                                        checked={this.state.cashcount}
                                        onChange={this.handleCashCountSwitch}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="discount-tax-switch"
                                        disabled={this._isViewMode()}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label
                                        htmlFor="validfrom"
                                        className="floatLeft switch-label"
                                    >
                                        Store Cash In/Out
                                    </label>
                                    <ReactSwitch
                                        checked={this.state.storecashinout}
                                        onChange={
                                            this.handleStoreCashInOutSwitch
                                        }
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="discount-tax-switch"
                                        disabled={this._isViewMode()}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="grid">
                        <div className="form-group col-4">
                            <label htmlFor="minamount" className="floatLeft">
                                Min Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="minamount"
                                value={this.state.minamount}
                                onChange={(event) =>
                                    this.setState({
                                        minamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="maxamount" className="floatLeft">
                                Max Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="maxamount"
                                value={this.state.maxamount}
                                onChange={(event) =>
                                    this.setState({
                                        maxamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="tendercount" className="floatLeft">
                                Tender Count (0-Unlimited)
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="tendercount"
                                value={this.state.tendercount}
                                onChange={(event) =>
                                    this.setState({
                                        tendercount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <label htmlFor="tenderimg" className="floatLeft">
                                Tender Image
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderimg"
                                value={this.state.tenderimg}
                                onChange={(event) =>
                                    this.setState({
                                        tenderimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <label
                                htmlFor="tenderinstruction"
                                className="floatLeft"
                            >
                                Tender Instructions
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderinstruction"
                                value={this.state.tenderinstruction}
                                onChange={(event) =>
                                    this.setState({
                                        tenderinstruction: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <label
                                htmlFor="tenderanimationimg"
                                className="floatLeft"
                            >
                                Tender Animation
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderanimationimg"
                                value={this.state.tenderanimationimg}
                                onChange={(event) =>
                                    this.setState({
                                        tenderanimationimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.tagtype === 'storeprops') {
            return (
                <React.Fragment>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="col-6">
                            <label
                                htmlFor="autolocktimeout"
                                className="floatLeft"
                            >
                                Autolock Timeout
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="autolocktimeout"
                                value={this.state.autolocktimeout}
                                onChange={(event) =>
                                    this.setState({
                                        autolocktimeout: event.target.value,
                                    })
                                }
                                min="0"
                                max="1000"
                                maxLength="4"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="col-6">
                            <label
                                htmlFor="paymenttimeout"
                                className="floatLeft"
                            >
                                Payment Timeout
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="paymenttimeout"
                                value={this.state.paymenttimeout}
                                onChange={(event) =>
                                    this.setState({
                                        paymenttimeout: event.target.value,
                                    })
                                }
                                min="0"
                                max="1000"
                                maxLength="4"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label
                                htmlFor="quicklookupcatcode"
                                className="floatLeft"
                            >
                                Quick Lookup Category Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="quicklookupcatcode"
                                value={this.state.quicklookupcatcode}
                                onChange={(event) =>
                                    this.setState({
                                        quicklookupcatcode: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="col-6">
                            <label
                                htmlFor="productplaceholderimg"
                                className="floatLeft"
                            >
                                Product Placeholder Img
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productplaceholderimg"
                                    value={this.state.productplaceholderimg}
                                    onChange={(event) =>
                                        this.setState({
                                            productplaceholderimg:
                                                event.target.value,
                                        })
                                    }
                                    disabled={true}
                                />
                            ) : (
                                <SimpleImageInput
                                    placeholder="Choose Image.."
                                    setImage={(value) => {
                                        this.setState({
                                            productplaceholderimg: value,
                                        });
                                    }}
                                    value={this.state.productplaceholderimg}
                                ></SimpleImageInput>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label
                                htmlFor="promotionprefix"
                                className="floatLeft"
                            >
                                Promotion Prefix
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="promotionprefix"
                                value={this.state.promotionprefix}
                                onChange={(event) =>
                                    this.setState({
                                        promotionprefix: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _renderOtherPropDetails = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                <div className="col-12 mb-2">
                    <label htmlFor="status" className="floatLeft">
                        Status
                    </label>
                    {this._isViewMode() ? (
                        <input
                            type="text"
                            className="form-control"
                            id="status"
                            value={this.state.status}
                            disabled={true}
                        />
                    ) : (
                        <StatusOptionComp
                            statusoption={this.editModeStatus}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    _renderMainForm = () => {
        return (
            <div className="grid px-3">
                <div className="col-9">
                    <form className="splitFrmDiv">
                        {this._renderBasicDetails()}
                        {this._renderTenderSettings()}
                    </form>
                </div>
                <div className="col-3">{this._renderOtherPropDetails()}</div>
            </div>
        );
    };

    componentWillReceiveProps = (nextProps) => {
        const {
            storeTenderGroupTypes,
            tenderTagTypes: propConfigs,
        } = nextProps.queryData;
    
        const hasAdditionalFields = (array) =>
            array && array.length > 0 && array[0].additionalfields;
    
        if (hasAdditionalFields(propConfigs)) {
            this.setState({
                tenderTagTypeOptions: {
                    ...propConfigs[0].additionalfields
                }
            });
        }
    
        if (hasAdditionalFields(storeTenderGroupTypes)) {
            const storeTenderGroupOptions = [
                { name: 'Select', id: '' },
                ...Object.entries(storeTenderGroupTypes[0].additionalfields).map(([key, value]) => ({
                    name: value,
                    id: key
                }))
            ];
            this.setState({
                storeTenderGroupOptions
            });
        }
    };
    

    componentWillMount = () => {
        const storeid = localStorage.getItem('storeid');
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    title,
                    taggroup,
                    tagtype,
                    tagtypevalue,
                    status,
                    additionalfields,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.taggroup = taggroup ? taggroup : '';
                        if (taggroup === 'storetenders') {
                        }
                        draft.tagtype = tagtype;
                        draft.tagtypevalue = tagtypevalue;
                        draft.status = status ? status : '';

                        if (additionalfields) {
                            //tender
                            if (additionalfields['collectiontender']) {
                                draft.collectiontender =
                                    additionalfields['collectiontender'] ===
                                    'Y';
                            }
                            if (additionalfields['tenderdesc']) {
                                draft.tenderdesc =
                                    additionalfields['tenderdesc'];
                            }
                            if (additionalfields['tender']) {
                                draft.tender =
                                    additionalfields['tender'] === 'Y';
                            }
                            if (additionalfields['loan']) {
                                draft.loan = additionalfields['loan'] === 'Y';
                            }
                            if (additionalfields['pickup']) {
                                draft.pickup =
                                    additionalfields['pickup'] === 'Y';
                            }
                            if (additionalfields['opencashdrawer']) {
                                draft.opencashdrawer =
                                    additionalfields['opencashdrawer'] === 'Y';
                            }
                            if (additionalfields['autopickup']) {
                                draft.autopickup =
                                    additionalfields['autopickup'] === 'Y';
                            }
                            if (additionalfields['enableoffline']) {
                                draft.enableoffline =
                                    additionalfields['enableoffline'] === 'Y';
                            }
                            if (additionalfields['returntender']) {
                                draft.returntender =
                                    additionalfields['returntender'] === 'Y';
                            }
                            if (additionalfields['voidtender']) {
                                draft.voidtender =
                                    additionalfields['voidtender'] === 'Y';
                            }
                            if (additionalfields['returntype']) {
                                draft.returntype =
                                    additionalfields['returntype'];
                            }
                            if (additionalfields['minamount']) {
                                draft.minamount = additionalfields['minamount'];
                            }
                            if (additionalfields['maxamount']) {
                                draft.maxamount = additionalfields['maxamount'];
                            }
                            if (additionalfields['tenderimg']) {
                                draft.tenderimg = additionalfields['tenderimg'];
                            }
                            if (additionalfields['tenderinstruction']) {
                                draft.tenderinstruction =
                                    additionalfields['tenderinstruction'];
                            }
                            if (additionalfields['tenderanimationimg']) {
                                draft.tenderanimationimg =
                                    additionalfields['tenderanimationimg'];
                            }
                            if (additionalfields['tendergroup']) {
                                draft.tendergroup =
                                    additionalfields['tendergroup'];
                            }
                            if (additionalfields['offlinetender']) {
                                draft.offlinetender =
                                    additionalfields['offlinetender'] === 'Y';
                            }
                            if (additionalfields['cashcount']) {
                                draft.cashcount =
                                    additionalfields['cashcount'] === 'Y';
                            }
                            if (additionalfields['mgrintervention']) {
                                draft.mgrintervention =
                                    additionalfields['mgrintervention'] === 'Y';
                            }
                            if (additionalfields['storecashinout']) {
                                draft.storecashinout =
                                    additionalfields['storecashinout'] === 'Y';
                            }
                            if (additionalfields['tendercount']) {
                                draft.tendercount =
                                    additionalfields['tendercount'];
                            }

                            //storeprops
                            if (additionalfields['autolocktimeout']) {
                                draft.autolocktimeout =
                                    additionalfields['autolocktimeout'];
                            }
                            if (additionalfields['paymenttimeout']) {
                                draft.paymenttimeout =
                                    additionalfields['paymenttimeout'];
                            }
                            if (additionalfields['quicklookupcatcode']) {
                                draft.quicklookupcatcode =
                                    additionalfields['quicklookupcatcode'];
                            }
                            if (additionalfields['productplaceholderimg']) {
                                draft.productplaceholderimg =
                                    additionalfields['productplaceholderimg'];
                            }
                            if (additionalfields['promotionprefix']) {
                                draft.promotionprefix =
                                    additionalfields['promotionprefix'];
                            }
                        }
                    })
                );
            }
        }

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TENDERTAGTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_TENDERTAGTYPES,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE
            )
        );
        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TENDER_GROUP_PTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES,
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS,
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE
            )
        );
    };

    componentDidUpdate = (prevProps) => {
        const { storeTags } = this.props.queryData;
        const { storeTags: prevstoreTags } = prevProps.queryData;

        const { tenderTagTypes } = this.props.queryData;
        const { tenderTagTypes: prevtenderTagTypes } = prevProps.queryData;

        if (tenderTagTypes !== prevtenderTagTypes) {
            if (
                tenderTagTypes &&
                tenderTagTypes.length > 0 &&
                tenderTagTypes[0].additionalfields
            ) {
                this.setState({
                    tenderTagTypeOptions: Object.assign(
                        { Select: '' },
                        tenderTagTypes[0].additionalfields
                    ),
                });
            }
        }

        if (storeTags !== prevstoreTags) {
            if (storeTags && storeTags.length > 0) {
                this.setState({
                    tagtypevalueerror: 'store tender already defined',
                });
            } else {
                this.setState({ tagtypevalueerror: '' });
            }
        }
    };

    checkTenderForStore = () => {
        let queryParam = `storeid=${this.state.storeid}&taggroup=storeprops&tagtype=tender&tagtypevalue=${this.state.tagtypevalue}&status=Active`;
        this.props.dispatch(storeTagsSearchByFields(queryParam));
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height px-2">
                    <div className="grid pl-3 ml-1 mb-4">
                        <h2 className="page-header">
                            {this._isEditMode()
                                ? 'Edit Store Tender'
                                : this._isViewMode()
                                  ? 'View Store Tender'
                                  : 'Add Store Tender'}
                        </h2>
                    </div>

                    <div className="grid">
                        {this._renderErrorMessage()}
                        
                    </div>
                    {this._renderMainForm()}
                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/storetenders"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Store Tender updated successfully'
                                    : 'Store Tender created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStoreTenders);
