import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import LinkButton from '../../../components/LinkButton';
import { searchFields, statusValues } from './tableHeader';
import Modal from 'react-awesome-modal';
import localforage from 'localforage';
import 'leaflet.offline';
import {
    deleteArticleInfoApi,
    unpublishArticleInfoApi,
    publisArticleInfoApi,
    copyArticleInfoApi,
    crudReset,
    searchGeneral,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import { Link } from 'react-router-dom';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import RefreshIcon from '../../../components/ImageComponents/RefreshIcon';
import SimpleSearcher from '../../../components/SimpleSearcher';
import SimpleTable from '../../../components/SimpleTable';
import MultiSelect from './../../../components/MultiSelect';
import {
    canDelete,
    canPublish,
    canView,
    canWrite,
    formatLanguageArray,
    permissions,
} from '../../../helpers/Util';
import { updateDefaultConfig } from '../../../actions/actions';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

class DisplayStoreList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_CMS_ST';
        this.module = 'CMS';
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 0,
            language: 'en',
            status: 'New',
            langlist: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            isMarkerButtons: false,
            tableCols: [
                {
                    dataField: 'articlecode',
                    text: 'Article Code',
                    sort: true,
                },
                {
                    dataField: 'language',
                    text: 'Language',
                    sort: true,
                    formatter: this.languageFormatter,
                },
                {
                    dataField: 'title',
                    text: 'Title',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: 'fields.desc',
                    text: 'Description',
                    sort: true,
                },
                {
                    dataField: 'articlegroup',
                    text: 'Article Group',
                    sort: true,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
        };
    }

    languageFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (
            this.props.queryData.languageConfig[0] &&
            this.props.queryData.languageConfig[0]['additionalfields']
        ) {
            return (
                this.props.queryData.languageConfig[0]['additionalfields'][
                    row.language
                ] || ''
            );
        }
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/store-information/addStoreInfo',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/store-information/addStoreInfo',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}
                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreInfo(row)}
                    />
                )}
                {canWrite(this.allPermissions) && (
                    <img
                        title="Copy"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        onClick={() => this.copyRecord(row)}
                    />
                )}

                {canPublish(this.allPermissions) && (
                    <img
                        title={
                            row.status === 'Active'
                                ? 'Unpublish Article'
                                : 'Publish Article'
                        }
                        className="tableImage2"
                        src={
                            row.status === 'Active'
                                ? `${process.env.PUBLIC_URL}/assets/icons/ic_unpublic.svg`
                                : `${process.env.PUBLIC_URL}/assets/icons/ic_public.svg`
                        }
                        onClick={
                            row.status === 'Active'
                                ? () => this.unpublishArticle(row)
                                : () => this.publishArticle(row)
                        }
                    />
                )}
            </span>
        );
    };

    componentDidUpdate = (prevProps) => {
        const { languageConfig } = this.props.queryData;
        const { languageConfig: prevlanguageConfig } = prevProps.queryData;
        if (languageConfig !== prevlanguageConfig) {
            if (languageConfig.length > 0) {
                const list = languageConfig[0].additionalfields;
                this.setState({
                    langlist: list,
                });
            }
        }
    };

    componentDidMount() {
        const { articleFlowData } = this.props.queryData;
        this.callQueryApiForFetch('ONLOAD')
        if (
            articleFlowData.length !== 0 &&
            this.state.search_field !== 'status' &&
            this.state.search_value !== 'New'
        ) {
            const map = L.map('leaflet_map_stores');
            if (map) {
                const offlineLayer = L.tileLayer.offline(
                    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    localforage,
                    {
                        attribution:
                            '&copy; <a href=”https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                        subdomains: 'abc',
                        minZoom: 13,
                        maxZoom: 19,
                        crossOrigin: true,
                    },
                );
                offlineLayer.addTo(map);
            }
        }
    }
    _closeMarkerButtonsModal = () => {
        this.setState({ isMarkerButtons: false });
    };
    _openMarkerButtonsModal = () => {
        this.setState({ isMarkerButtons: true });
    };

    _renderMarkerButtonsModal = (row) => {
        return (
            <Modal
                visible={this.state.isMarkerButtons}
                width="80%"
                height="80%"
                effect="fadeInUp"
                onClickAway={() => this._closeMarkerButtonsModal()}
            >
                <div className="modalForm storemodalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h5>Store Details</h5>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={this._closeMarkerButtonsModal}>X</a>
                        </div>
                    </div>
                    <div className="row modalText">
                        <div className="col-xs-12 col-md-12">{`Store : ${row.title}`}</div>
                        <div className="col-xs-12 col-md-12">{`Address : ${row.fields.address1},${row.fields.address2},${row.fields.city},${row.fields.country}`}</div>
                        <div className="col-xs-12 col-md-12">{`Contact : ${row.fields.contact}`}</div>
                        <div className="col-xs-12 col-md-12">
                            <span>
                                <Link
                                    to={{
                                        pathname:
                                            '/ui/store-information/addStoreInfo',
                                        state: {
                                            item: row,
                                            isEdit: true,
                                        },
                                    }}
                                >
                                    <img
                                        title="Edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                                    />
                                </Link>

                                <img
                                    title="Delete"
                                    className="tableImage"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                    onClick={() => this.deleteStoreInfo(row)}
                                />
                                <img
                                    title="Copy"
                                    className="tableImage"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                                    onClick={() => this.copyRecord(row)}
                                />
                                <img
                                    title={
                                        row.status === 'Active'
                                            ? 'Unpublish Article'
                                            : 'Publish Article'
                                    }
                                    className="tableImage2"
                                    src={
                                        row.status === 'Active'
                                            ? `${process.env.PUBLIC_URL}/assets/icons/ic_unpublic.svg`
                                            : `${process.env.PUBLIC_URL}/assets/icons/ic_public.svg`
                                    }
                                    onClick={
                                        row.status === 'Active'
                                            ? () => this.unpublishArticle(row)
                                            : () => this.publishArticle(row)
                                    }
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    copyRecord = (row) => {
        this.setState({ message: 'Item Copied Suucessfully' });
        this.props.dispatch(copyArticleInfoApi(row.id));
    };
    MyPopupMarker = (row, id) => {
        const { fields, title } = row;
        let latitude;
        let key = id;
        let longitude;
        if (fields) {
            if (fields.latitude) {
                latitude = JSON.parse(fields.latitude);
            }
            if (fields.longitude) {
                longitude = JSON.parse(fields.longitude);
            }
        }
        if (latitude && longitude) {
            return (
                <Marker
                    key={id}
                    position={[latitude, longitude]}
                    onClick={this._openMarkerButtonsModal}
                >
                    <Popup key={id}>{title}</Popup>
                    {this._renderMarkerButtonsModal(row)}
                </Marker>
            );
        }
    };
    onMapReady(map) {
        setTimeout(() => {
            map.invalidateSize();
        }, 0);
    }
    MyMarkersList = (markers) => {
        const items = markers.map((row, { id, ...props }) => {
            return this.MyPopupMarker(row, row.id, { ...props });
        });
        return <React.Fragment>{items}</React.Fragment>;
    };
    // API Calls
    createFetchUrl = () => {
        const { language, search_field, search_condi, search_value } =
            this.state;

        const requestBody = {
            body: {
                articletype: 'Store Information',
                language: language.toString(),
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'cms/v1/article-workflow/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            },
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        // this.setState({ language: defLanguage.toString().split(',') }, () => {
        //     this.callQueryApiForFetch('ONLOAD');
        // });
        this.allPermissions = permissions(this.module, this.group);
    }

    _renderAddNewBtn = () => {
        if (!canWrite(this.allPermissions)) {
            return;
        }
        return (
            <Link
                to={{
                    pathname: '/ui/store-information/addStoreInfo',
                    state: {
                        language: this.state.language[0],
                    },
                }}
            >
                <Button
                    label="Add New"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    type="button"
                />
            </Link>
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                unpublishArticleInfoApi(this.state.delete_rec_id),
            );
            this.props.dispatch(deleteArticleInfoApi(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    deleteStoreInfo = (rowData) => {
        this.setState({ message: 'Store Details deleted successfully!' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };
    unpublishArticle = (rowData) => {
        this.setState({ message: 'Store Details unpublished successfully!' });
        this.props.dispatch(unpublishArticleInfoApi(rowData.id));
    };
    publishArticle = (rowData) => {
        this.setState({ message: 'Store Details published successfully!' });
        this.props.dispatch(publisArticleInfoApi(rowData.id));
    };
    _renderNoData = () => {
        return (
            <React.Fragment>
                <div className="row noStoreform">
                    <div className="col-lg-6 col-sm-12">
                        <p className="noStoreHeader">Add your store items</p>
                        <p className="noStoreBody">
                            Adding your items into your store, It allows you
                            effectively to add items in different store and
                            region in one time.
                        </p>
                        {this.allPermissions.includes('Write') && (
                            <div>
                                <LinkButton
                                    to="/ui/store-information/addStoreInfo"
                                    className="btn btn-themes btn-rounded"
                                    disabled={
                                        !this.allPermissions.includes('Write')
                                    }
                                    onClick={(event) => event.preventDefault()}
                                >
                                    Add Store
                                </LinkButton>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <img
                            className="noStoreImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_storeitems.svg`}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h2 className="page-header">
                        Store Information
                        <RefreshIcon
                            handleClick={() => this.callQueryApiForFetch('ONLOAD')}
                        />
                    </h2>
                </div>
                <div className="col-6 alignRight alignWithPageHeader">
                    {this._renderAddNewBtn()}
                </div>
            </div>
        );
    };

    render() {
        const {
            articleFlowData,
            errormsg: queryError,
            languageConfig,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
        if (this.props.queryData.apiPending === true) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <div className="form_height">
                    {this._renderHeader()}
                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="title_devide" />
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>

                                <React.Fragment>
                                    {articleFlowData.length === 0 &&
                                    this.state.search_field === 'status' &&
                                    this.state.search_value === 'New' ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <div
                                            id="leaflet_map_stores"
                                            style={{ height: '30em' }}
                                        >
                                            <Map
                                                center={[
                                                    '1.324984',
                                                    '103.929349',
                                                ]}
                                                zoom={3}
                                                style={{ height: '30em' }}
                                                scrollWheelZoom={false}
                                            >
                                                <TileLayer
                                                    zIndex="0"
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                {this.MyMarkersList(
                                                    articleFlowData,
                                                )}
                                            </Map>
                                        </div>
                                    )}
                                    <div className="row storeSearch">
                                        <div className="col-lg-5 col-sm-12 alignRight noPadding noMargin"></div>
                                        <div className="col-lg-5 col-sm-12 alignRight noPadding noMargin">
                                            <SimpleSearcher
                                                filterOptions={searchFields}
                                                setAndSearch={this.searchQuery}
                                                search_condi={
                                                    this.state.search_condi
                                                }
                                                search_value={
                                                    this.state.search_value
                                                }
                                                search_field={
                                                    this.state.search_field
                                                }
                                                searchValues={statusValues}
                                            ></SimpleSearcher>
                                        </div>
                                        <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                                            <MultiSelect
                                                filterOptions={formatLanguageArray(
                                                    this.state.langlist,
                                                )}
                                                setSelectedFieldValues={(
                                                    value,
                                                ) => {
                                                    this.setState(
                                                        { language: value },
                                                        () => {
                                                            this.callQueryApiForFetch(
                                                                'ONLOAD',
                                                            );
                                                        },
                                                    );
                                                    this.props.dispatch(
                                                        updateDefaultConfig(
                                                            {
                                                                body: {
                                                                    language:
                                                                        value,
                                                                },
                                                            },
                                                            () => {
                                                                this.callQueryApiForFetch(
                                                                    'ONLOAD',
                                                                );
                                                            },
                                                        ),
                                                    );
                                                }}
                                                id="language"
                                                itemsSelected={
                                                    this.state.language
                                                }
                                                label="Select Language"
                                            />
                                        </div>
                                    </div>

                                    <SimpleTable
                                        columns={this.state.tableCols}
                                        rows={articleFlowData}
                                        callback={() => {
                                            this.callQueryApiForFetch('ONLOAD');
                                        }}
                                    />
                                </React.Fragment>
                            </form>
                        </div>
                    </div>

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this store
                                information?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayStoreList);
