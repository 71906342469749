import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import ExportButton from '../../../components/TableExportButton';
import { searchGeneral } from '../../../actions/actions';
import PrimeTable from '../../../components/PrimeTable';

const getPastDate = () => {
    const currentDate = moment();
    const pastDate = moment(currentDate).subtract(30, 'days');
    return pastDate.format('DD-MM-YYYY');
};

const CustomerCouponList = (props) => {
    const [state, setState] = useState({
        search_field: 'couponcode',
        search_condi: 'like',
        search_value: '',
        date_from: getPastDate(),
        date_till: moment().format('DD-MM-YYYY'),
        date_type: 'createdon',
    });
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);

    const { userProfileList, errormsg: queryError } = props.queryData;

    useEffect(() => {
        callFetch();
    }, []);

    const createFetchUrl = () => {
        const {
            search_field,
            search_condi,
            search_value,
            date_from,
            date_till,
            date_type,
        } = state;

        const requestBody = {
            body: {
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
                date_from,
                date_till,
                date_type: date_type,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
        return requestBody;
    };

    const callFetch = () => {
        props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/transaction/search',
                createFetchUrl(),
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    };

    const pageSizeChangecallback = (rowCount) => {
        setPageSize(rowCount);
        const requestBody = createFetchUrl();
        requestBody.body['pagesize'] = rowCount;
        props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/transaction/search',
                requestBody,
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    };

    const pageChangeCallback = (pageNumber) => {
        setCurrPage(pageNumber);
        const requestBody = createFetchUrl();
        requestBody.body['pageno'] = pageNumber;
        props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/transaction/search',
                requestBody,
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    };

    const actionTemplate = (row) => {
        return (
            <span>
                <Link
                    to={{
                        pathname: '/ui/member-coupon/detail',
                        state: {
                            item: row,
                            isView: true,
                        },
                    }}
                >
                    <img
                        title="View"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    />
                </Link>
            </span>
        );
    };

    const columns = [
        { field: 'couponcode', header: 'Coupon Code' },
        { field: 'title', header: 'Title', sortable: true },
        { field: 'rulecode', header: 'Rule Code', sortable: true },
        { field: 'couponno', header: 'Coupon No', sortable: true },
        { field: 'couponqty', header: 'Coupon Quantity', sortable: true },
        { field: 'memberid', header: 'Customer No', sortable: true },
        { field: 'category', header: 'Category', sortable: true },
        { field: 'issuemode', header: 'Issue Mode', sortable: true },
        { field: 'status', header: 'Status' },
    ];

    const getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.couponcode,
            e.title,
            e.rulecode,
            e.couponno,
            e.memberid,
            e.category,
            e.issuemode,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    const formik = useFormik({
        initialValues: {
            search_field: '',
            search_value: '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                search_field: values.search_field.id,
                search_value: values.search_value,
            };

            handleSearch(data);
        },
    });

    const handleSearch = (data) => {
        const { search_condi, date_from, date_till, date_type } = state;

        const requestBody = {
            body: {
                search_field: data.search_field,
                search_condi: search_condi,
                search_value: data.search_value,
                date_from,
                date_till,
                date_type: date_type,
                pagesize: pageSize,
                pageno: currPage,
            },
        };

        props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/transaction/search',
                requestBody,
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    };

    const handleClear = () => {
        formik.resetForm();
        callFetch();
    };

    const _renderSearchCiteria = () => {
        const searchField = [
            { name: 'Customer No', id: 'memberid' },
            { name: 'Coupon Code', id: 'couponcode' },
            { name: 'Rule Code', id: 'rulecode' },
            { name: 'Coupon No', id: 'couponno' },
            { name: 'Status', id: 'status' }
        ];
        return (
            <form onSubmit={formik.handleSubmit} className="w-full">
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-3 md:col-3">
                        <label htmlFor="search_field">Search By</label>
                        <Dropdown
                            name="search_field"
                            value={formik.values.search_field}
                            onChange={formik.handleChange}
                            options={searchField}
                            optionLabel="name"
                            className="w-full"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="search_value" className="floatLeft">
                            Search Value
                        </label>
                        <InputText
                            id="search_value"
                            name="search_value"
                            value={formik.values.search_value}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field mb-2" style={{ display: 'flex' }}>
                        <div className="mr-4" style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="button"
                                label="Cancel"
                                className="p-button-secondry mr-4"
                                onClick={handleClear}
                                severity="secondary"
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const renderCouponPublishedReport = () => {
        const { errormsg: crudError } = props.crudData;
        let errormsg = '';
        if (queryError) {
            errormsg = queryError;
        }
        if (crudError) {
            errormsg = queryError;
        }

        return (
            <React.Fragment>
                <div className="flex w-full">{_renderSearchCiteria()}</div>
                <div className="title_devide" />
                <PrimeTable
                    list={userProfileList}
                    columns={columns}
                    actionColumn={actionTemplate}
                    pageSizeChangecallback={(rowCount) => {
                        pageSizeChangecallback(rowCount);
                    }}
                    pageChangeCallback={(pageNo) => {
                        pageChangeCallback(pageNo);
                    }}
                />
            </React.Fragment>
        );
    };

    const tableCols = [
        {
            dataField: 'couponcode',
            text: 'Coupon Code',
            sort: true,
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'rulecode',
            text: 'Rule Code',
            sort: true,
        },
        {
            dataField: 'couponno',
            text: 'Coupon No',
            sort: true,
        },
        {
            dataField: 'memberid',
            text: 'Customer Id',
            sort: true,
        },
        {
            dataField: 'category',
            text: 'Category',
            sort: true,
        },
        {
            dataField: 'issuemode',
            text: 'Issue Mode',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
    ];

    return (
        <div className="form_height">
            <div className="row">
                <div className="col-md-9">
                    <h2 className="page-header">
                        Customer Coupon
                        <img
                            title="Refresh"
                            onClick={() => {
                                callFetch();
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h2>
                </div>
                <div className="col-md-3 text-right noPadding noMargin">
                    <ExportButton
                        title="Coupon List"
                        tabledata={getTableData(userProfileList, tableCols)}
                    />
                </div>
            </div>
            <div className="row form-container">
                <div className="col">
                    <div className="frmDiv">
                        {renderCouponPublishedReport()}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(CustomerCouponList);
