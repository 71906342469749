import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const StatusOptionComp = ({ selectedOption, statusoption, callbackChangeFilter }) => {
    const [selected, setSelected] = useState(selectedOption);

    useEffect(() => {
        setSelected(selectedOption);
    }, [selectedOption]);

    const dropDownChange = (event) => {
        const value = event.target.value;
        setSelected(value);
        callbackChangeFilter(value);
    };
    
    return (
        <div className="filterClass form-filter">
            <select
                id="statusComp"
                className="form-control"
                value={selected}
                onChange={dropDownChange}
            >
                {statusoption.length !== 0 ? (
                    statusoption.map((option, index) => {
                        const { name, id } = option;
                        return (
                            <option key={index} value={id}>
                                {name}
                            </option>
                        );
                    })
                ) : (
                    <option>No option</option>
                )}
            </select>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(StatusOptionComp);
