import * as CONSTANTS from "../constants/constants";

export const initialState = {
  mediaList: [],
  articleFlowData: [],
  statusConfig: [],
  languageConfig: [],
  sortConfig: [],
  storeGrpConfig: [],
  memberProps: [],
  memberTxnProps: [],
  memberByGroup: [],
  operatorsConfig: [],
  couponOptions: [],
  couponValidityConfig: [],
  couponInvSearch: [],
  freqConfig: [],
  countryConfig: [],
  articleFormData: [],
  userProfileList: [],
  memberTagList: [],
  salesCategoryList: [],
  categoryTree: [],
  recommendcategoryTree: [],
  similarcategoryTree: [],
  prodSpecList: [],
  defaultConfigList: [],
  mobileMsgTypes: [],
  beaconBrands: [],
  productList: [],
  dynamicFields: [],
  storeCategoryMapping: [],
  saleSummaryByDate: [],
  saleSummaryByHour: [],
  saleSummaryByMonth: [],
  saleSummaryByYear: [],
  productSaleSummary: [],
  memberSaleSummary: [],
  memberDeviceModel: [],
  memberDeviceType: [],
  newMemberDevice: [],
  activeMemberDevice: [],
  inactiveMemberDevice: [],
  memberDeviceTypeDate: [],
  guestDeviceTypeDate: [],
  guestDeviceModel: [],
  guestDeviceType: [],
  deviceInfo: {},
  foldersList: [],
  cartList: [],
  cart: [],
  apiPending: false,
  errormsg: "",
  isSuccess: false,
  beaconsList: [],
  geoFencesList: [],
  couponCodeList: [],
  couponRuleList: [],
  couponsList: [],
  articleMode: "",
  articleModeType: "",
  articleOptions: [],
  webArticleOptions: [],
  productStoreUpdate: [],
  productGroupsByCategory: [],
  productSuggestions: [],
  storeproductdata: [],
  invData: [],
  invTrxData: [],
  taxValue: [],
  specialDiscounts: {},
  prodKeySearch: [],
  dataByCountry: [],
  storeArray: [],
  categoryCodeArray: [],
  servererror: "",
  storeproductcode: [],
  propconfigList: [],
  deliverytypeList: [],
  deliverycountryList: [],
  deliveryslotList: [],
  sizePropList: [],
  colorPropList: [],
  prodFeatureList: [],
  deliveryTaskList: [],
  shippingTaskList: [],
  returnTaskList: [],
  listByProduct: [],
  salesReportData: [],
  trackerInventoryReportData: [],
  trackerSearchKeyReportData: [],
  memberReportData: [],
  saleDocument: [],
  users: [],
  usersByuserId: [],
  userGroups: [],
  userRoles: [],
  userRolesAccess: [],
  userAccess: [],
  storeGroups: [],
  stores: [],
  storeSearchStores: [],
  companies: [],
  countries: [],
  cities: [],
  promotions: [],
  promotionspurchasegroups: [],
  promotionstargetgroups: [],
  storeDevices: [],
  customerDisplays: [],
  virtualPrinters: [],
  storeTerminals: [],
  storeTags: [],
  peripheralSearchDevices: [],
  apilogs: [],
  apilog: {},
  jobtasklogs: [],
  coupontrxexports: [],
  reportexport: {},
  ejttexport: {},
  jobtasklog: {},
  storeproducts: [],
  couponTrxs: [],
  couponexport: {},
  salesDetailsReport: [],
  posMasterDetailsReport: [],
  tenderListingReport: [],
  refundDetailsReport: [],
  tagDetailsReport: [],
  propConfigs: [],
  storeSessions: [],
  productPriceDetailsReport: [],
  productSalesSummaryReport: [],
  masterTrxTypes: [],
  ejTrxTypes: [],
  ej: [],
  payStatus: [],
  salesTrxTypes: [],
  jobTaskTypes: [],
  masterTrxGroups: [],
  refundTypes: [],
  payTypes: [],
  tenderTagTypes: [],
  topupTagTypes: [],
  //tax configuration
  taxentries: [],
  specialdiscountpid: [],
  specialdiscounts: [],
  datasynclogs: [],
  datasynlog: {},
  storeBIRInfo: [],
  terminalBIRInfo: [],
  dataValidationTaskGroups: [],
  itemSalesTypes: [],
  serviceReportTypes: [],
  eipMessages: [],
  cartSyncList: [],
  specialDiscountSummary: [],
  specialDiscountTypeData: [],
  masterTrxGrpTypes: [],
  collectorUsers: [],
  replenishmentList: [],
  saleReturnList: [],
  storeTenderGroupTypes: [],
  paymentInvoiceList: [],
  saleChannelTypes: [],
  deviceVendorTypes: []
};

const queryReducer = (
  state = initialState,
  { type, data, errormsg, servererror }
) => {
  switch (type) {
    case CONSTANTS.API_PENDING:
      return {
        ...state,
        apiPending: true
      };

    case CONSTANTS.GET_MEDIA_LIST_SUCCESS:
    case CONSTANTS.GET_MEDIA_LIST_FAILURE:
      return {
        ...state,
        mediaList: data,
        apiPending: false,
        //errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_MEDIA_FOLDERS_SUCCESS:
    case CONSTANTS.GET_MEDIA_FOLDERS_FAILURE:
      return {
        ...state,
        foldersList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.STORE_GROUP_BY_TITLE_SUCCESS:
    case CONSTANTS.STORE_GROUP_BY_TITLE_FAILURE:
      localStorage.setItem("storegrouptitle", JSON.stringify(data.pvalues));
      return {
        ...state,
        storeGrpConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_USERPROFILE_SUCCESS:
    case CONSTANTS.SEARCH_USERPROFILE_FAILURE:
      return {
        ...state,
        memberByGroup: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_PROPS_BY_TITLE_SUCCESS:
    case CONSTANTS.MEMBER_PROPS_BY_TITLE_FAILURE:
      return {
        ...state,
        memberProps: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.OPERATORS_BY_TITLE_SUCCESS:
    case CONSTANTS.OPERATORS_BY_TITLE_FAILURE:
      return {
        ...state,
        operatorsConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_TXN_BY_TITLE_SUCCESS:
    case CONSTANTS.MEMBER_TXN_BY_TITLE_FAILURE:
      return {
        ...state,
        memberTxnProps: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.FREQ_BY_TITLE_SUCCESS:
    case CONSTANTS.FREQ_BY_TITLE_FAILURE:
      return {
        ...state,
        freqConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.COUPON_VALIDITY_BY_TITLE_SUCCESS:
    case CONSTANTS.COUPON_VALIDITY_BY_TITLE_FAILURE:
      return {
        ...state,
        couponValidityConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };

    case CONSTANTS.COUNTRY_CONFIG_BY_TITLE_SUCCESS:
    case CONSTANTS.COUNTRY_CONFIG_BY_TITLE_FAILURE:
      return {
        ...state,
        countryConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_BEACON_BRAND_BY_TITLE_SUCCESS:
    case CONSTANTS.GET_BEACON_BRAND_BY_TITLE_FAILURE:
      return {
        ...state,
        beaconBrands: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS:
    case CONSTANTS.GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE:
      localStorage.setItem("mobilemsgtype", JSON.stringify(data.pvalues));
      return {
        ...state,
        mobileMsgTypes: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.FETCH_ARTCILE_FORM_SUCCESS:
    case CONSTANTS.FETCH_ARTCILE_FORM_FAILURE:
      return {
        ...state,
        articleFormData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_ARTICLE_INFO_SUCCESS:
    case CONSTANTS.SEARCH_ARTICLE_INFO_FAILURE:
      return {
        ...state,
        articleFlowData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_SALEDOC_SUCCESS:
    case CONSTANTS.SEARCH_SALEDOC_FAILURE:
      return {
        ...state,
        saleDocument: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.STATUS_CONFIG_BY_TITLE_SUCCESS:
    case CONSTANTS.STATUS_CONFIG_BY_TITLE_FAILURE:
      localStorage.setItem("statusconfig", JSON.stringify(data && data.length > 0 ? data[0].pvalues : ''));
      return {
        ...state,
        statusConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.LANGUAGE_CONFIG_BY_TITLE_SUCCESS:
    case CONSTANTS.LANGUAGE_CONFIG_BY_TITLE_FAILURE:
      return {
        ...state,
        languageConfig: data,
        apiPending: false,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_MEMBER_PROFILE_SUCCESS:
    case CONSTANTS.SEARCH_MEMBER_PROFILE_FAILURE:
      return {
        ...state,
        userProfileList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_MEMBER_TAG_SUCCESS:
    case CONSTANTS.SEARCH_MEMBER_TAG_FAILURE:
      return {
        ...state,
        memberTagList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SALES_SEARCH_SUCCESS:
    case CONSTANTS.SALES_SEARCH_FAILURE:
      return {
        ...state,
        salesCategoryList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.FETCH_PROD_SPEC_SUCCESS:
    case CONSTANTS.FETCH_PROD_SPEC_FAILURE:
      return {
        ...state,
        prodSpecList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_SIZECONFIG_SUCCESS:
    case CONSTANTS.SEARCH_SIZECONFIG_FAILURE:
      return {
        ...state,
        sizePropList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_BY_PRODUCT_SUCCESS:
    case CONSTANTS.SEARCH_BY_PRODUCT_FAILURE:
      return {
        ...state,
        listByProduct: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PRODUCT_FEATURE_SUCCESS:
    case CONSTANTS.SEARCH_PRODUCT_FEATURE_FAILURE:
      return {
        ...state,
        prodFeatureList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_COLORCONFIG_SUCCESS:
    case CONSTANTS.SEARCH_COLORCONFIG_FAILURE:
      return {
        ...state,
        colorPropList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.FETCH_ALL_DEFAULT_CONFIG_SUCCESS:
    case CONSTANTS.FETCH_ALL_DEFAULT_CONFIG_FAILURE:
      return {
        ...state,
        defaultConfigList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.CATEGORY_TREE_SUCCESS:
    case CONSTANTS.CATEGORY_TREE_FAILURE:
      return {
        ...state,
        categoryTree: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.CATEGORYTREE_RECOMMENDCODE_SUCCESS:
    case CONSTANTS.CATEGORYTREE_RECOMMENDCODE_FAILURE:
      return {
        ...state,
        recommendcategoryTree: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.CATEGORYTREE_SIMILARCODE_SUCCESS:
    case CONSTANTS.CATEGORYTREE_SIMILARCODE_FAILURE:
      return {
        ...state,
        similarcategoryTree: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.FETCH_PRODUCTS_CATALOG_SUCCESS:
    case CONSTANTS.FETCH_PRODUCTS_CATALOG_FAILURE:
      return {
        ...state,
        productList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PROD_CAT_SEARCH_SUCCESS:
    case CONSTANTS.PROD_CAT_SEARCH_FAILURE:
      return {
        ...state,
        dynamicFields: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.STORE_CAT_SEARCH_SUCCESS:
    case CONSTANTS.STORE_CAT_SEARCH_FAILURE:
      return {
        ...state,
        storeCategoryMapping: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.STORE_PRODUCTCODE_SUCCESS:
    case CONSTANTS.STORE_PRODUCTCODE_FAILURE:
      return {
        ...state,
        storeproductcode: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SALES_SUMMARY_DATE_SUCCESS:
    case CONSTANTS.SALES_SUMMARY_DATE_FAILURE:
      return {
        ...state,
        saleSummaryByDate: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SALES_SUMMARY_HOUR_SUCCESS:
    case CONSTANTS.SALES_SUMMARY_HOUR_FAILURE:
      return {
        ...state,
        saleSummaryByHour: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SALES_SUMMARY_MONTH_SUCCESS:
    case CONSTANTS.SALES_SUMMARY_MONTH_FAILURE:
      return {
        ...state,
        saleSummaryByMonth: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SALES_SUMMARY_YEAR_SUCCESS:
    case CONSTANTS.SALES_SUMMARY_YEAR_FAILURE:
      return {
        ...state,
        saleSummaryByYear: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PRODUCT_SALES_SUMMARY_SUCCESS:
    case CONSTANTS.PRODUCT_SALES_SUMMARY_FAILURE:
      return {
        ...state,
        productSaleSummary: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_SALES_SUMMARY_SUCCESS:
    case CONSTANTS.MEMBER_SALES_SUMMARY_FAILURE:
      return {
        ...state,
        memberSaleSummary: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_DEVICE_INFO_SUCCESS:
    case CONSTANTS.MEMBER_DEVICE_INFO_FAILURE:
      return {
        ...state,
        deviceInfo: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_DEVICE_MODEL_SUCCESS:
    case CONSTANTS.MEMBER_DEVICE_MODEL_FAILURE:
      return {
        ...state,
        memberDeviceModel: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_DEVICE_TYPE_SUCCESS:
    case CONSTANTS.MEMBER_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        memberDeviceType: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.ACTIVE_MEMBER_DEVICE_TYPE_SUCCESS:
    case CONSTANTS.ACTIVE_MEMBER_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        activeMemberDevice: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.NEW_MEMBER_DEVICE_TYPE_SUCCESS:
    case CONSTANTS.NEW_MEMBER_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        newMemberDevice: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.INACTIVE_MEMBER_DEVICE_TYPE_SUCCESS:
    case CONSTANTS.INACTIVE_MEMBER_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        inactiveMemberDevice: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.MEMBER_DEVICE_TYPE_DATE_SUCCESS:
    case CONSTANTS.MEMBER_DEVICE_TYPE_DATE_FAILURE:
      return {
        ...state,
        memberDeviceTypeDate: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GUEST_DEVICE_MODEL_SUCCESS:
    case CONSTANTS.GUEST_DEVICE_MODEL_FAILURE:
      return {
        ...state,
        guestDeviceModel: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GUEST_DEVICE_TYPE_SUCCESS:
    case CONSTANTS.GUEST_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        guestDeviceType: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GUEST_DEVICE_TYPE_DATE_SUCCESS:
    case CONSTANTS.GUEST_DEVICE_TYPE_DATE_FAILURE:
      return {
        ...state,
        guestDeviceTypeDate: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_PRODUCTREPORTTIME_SUCCESS:
    case CONSTANTS.SEARCH_PRODCUTREPORTTIME_FAILURE:
      return {
        ...state,
        salesReportData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_TRACKER_INVENTORY_SUCCESS:
    case CONSTANTS.SEARCH_TRACKER_INVENTORY_FAILURE:
      return {
        ...state,
        trackerInventoryReportData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_TRACKER_SEARCHKEY_SUCCESS:
    case CONSTANTS.SEARCH_TRACKER_SEARCHKEY_FAILURE:
      return {
        ...state,
        trackerSearchKeyReportData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_MEMBERREPORTTIME_SUCCESS:
    case CONSTANTS.SEARCH_MEMBERREPORTTIME_FAILURE:
      return {
        ...state,
        memberReportData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PRODUCT_UPDATE_SUCCESS:
    case CONSTANTS.PRODUCT_UPDATE_FAILURE:
      return {
        ...state,
        productStoreUpdate: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PRODUCT_GROUP_SUCCESS:
    case CONSTANTS.PRODUCT_GROUP_FAILURE:
      return {
        ...state,
        productGroupsByCategory: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PRODUCT_SUGG_SUCCESS:
    case CONSTANTS.PRODUCT_SUGG_FAILURE:
      return {
        ...state,
        productSuggestions: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.CART_SEARCH_SUCCESS:
    case CONSTANTS.CART_SEARCH_FAILURE:
      return {
        ...state,
        cartList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_BY_ORDER_SESSION_ID_SUCCESS:
    case CONSTANTS.GET_BY_ORDER_SESSION_ID_FAILURE:
      return {
        ...state,
        cartList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_BY_ORDER_ID_SUCCESS:
    case CONSTANTS.GET_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        cart: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_BEACONS_SUCCESS:
    case CONSTANTS.GET_BEACONS_FAILURE:
      return {
        ...state,
        beaconsList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.GET_GEOFENCE_SUCCESS:
    case CONSTANTS.GET_GEOFENCE_FAILURE:
      return {
        ...state,
        geoFencesList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_COUPONRULE_SUCCESS:
    case CONSTANTS.SEARCH_COUPONRULE_FAILURE:
      return {
        ...state,
        couponRuleList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_COUPON_SUCCESS:
    case CONSTANTS.SEARCH_COUPON_FAILURE:
      return {
        ...state,
        couponsList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
      case CONSTANTS.SEARCH_COUPON_BY_FIELDS_SUCCESS:
      case CONSTANTS.SEARCH_COUPON_BY_FIELDS_FAILURE:
        return {
          ...state,
          couponsList: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
    case CONSTANTS.GET_COUPONCODE_SUCCESS:
    case CONSTANTS.GET_COUPONCODE_FAILURE:
      return {
        ...state,
        couponCodeList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PROPCONFIG_SUCCESS:
    case CONSTANTS.SEARCH_PROPCONFIG_FAILURE:
      return {
        ...state,
        propconfigList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_CNCONFIG_SUCCESS:
    case CONSTANTS.SEARCH_CNCONFIG_FAILURE:
      return {
        ...state,
        deliverycountryList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_DTYPECONFIG_SUCCESS:
    case CONSTANTS.SEARCH_DTYPECONFIG_FAILURE:
      return {
        ...state,
        deliverytypeList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_DELIVERYSLOT_SUCCESS:
    case CONSTANTS.SEARCH_DELIVERYSLOT_FAILURE:
      return {
        ...state,
        deliveryslotList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_BY_STOREPRODID_SUCCESS:
    case CONSTANTS.SEARCH_BY_STOREPRODID_FAILURE:
      return {
        ...state,
        storeproductdata: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.UPDATE_ARTICLE_MODE: {
      return {
        ...state,
        articleMode: data.mode
      };
    }
    case CONSTANTS.UPDATE_ARTICLE_MODE_TYPE: {
      return {
        ...state,
        articleModeType: data.mode
      };
    }
    case CONSTANTS.ARTICLE_OPTIONS_SUCCESS:
    case CONSTANTS.ARTICLE_OPTIONS_FAILURE:
      return {
        ...state,
        articleOptions: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.STORE_INV_SEARCH_SUCCESS:
    case CONSTANTS.STORE_INV_SEARCH_FAILURE:
      return {
        ...state,
        invData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.INV_TRX_SEARCH_SUCCESS:
    case CONSTANTS.INV_TRX_SEARCH_FAILURE:
      return {
        ...state,
        invTrxData: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.TAX_VALUE_SEARCH_SUCCESS:
    case CONSTANTS.TAX_VALUE_SEARCH_FAILURE:
      return {
        ...state,
        taxValue: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.PROD_KEY_SEARCH_SUCCESS:
    case CONSTANTS.PROD_KEY_SEARCH_FAILURE:
      return {
        ...state,
        prodKeySearch: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_CITY_BY_COUNTRY_SUCCESS:
    case CONSTANTS.SEARCH_CITY_BY_COUNTRY_FAILURE:
      return {
        ...state,
        dataByCountry: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_BY_ID_SUCCESS:
    case CONSTANTS.SEARCH_STORE_BY_ID_FAILURE:
      return {
        ...state,
        storeArray: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_BY_CC_SUCCESS:
    case CONSTANTS.SEARCH_BY_CC_FAILURE:
      return {
        ...state,
        categoryCodeArray: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_DELIVERYTASK_SUCCESS:
    case CONSTANTS.SEARCH_DELIVERYTASK_FAILURE:
      return {
        ...state,
        deliveryTaskList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_SHIPPINGTASK_SUCCESS:
    case CONSTANTS.SEARCH_SHIPPINGTASK_FAILURE:
      return {
        ...state,
        shippingTaskList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };

    case CONSTANTS.SEARCH_RETURNTASK_SUCCESS:
    case CONSTANTS.SEARCH_RETURNTASK_FAILURE:
      return {
        ...state,
        returnTaskList: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.CONTENT_CHANGE: {
      return {
        ...state,
        emailbody: data
      };
    }
    case CONSTANTS.PRODUCT_ARTICLE_RESET:
      return {
        ...state,
        articleFlowData: initialState.articleFlowData
      };
    case CONSTANTS.SEARCH_USERS_BY_USERID_SUCCESS:
      case CONSTANTS.SEARCH_USERS_BY_USERID_FAILURE:
        return {
          ...state,
          usersByuserId: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
    case CONSTANTS.SEARCH_USERS_SUCCESS:
    case CONSTANTS.SEARCH_USERS_FAILURE:
      return {
        ...state,
        users: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_USERGROUPS_SUCCESS:
    case CONSTANTS.SEARCH_USERGROUPS_FAILURE:
      return {
        ...state,
        userGroups: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_USERROLES_SUCCESS:
    case CONSTANTS.SEARCH_USERROLES_FAILURE:
      return {
        ...state,
        userRoles: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_USERROLESACCESS_SUCCESS:
    case CONSTANTS.SEARCH_USERROLESACCESS_FAILURE:
      return {
        ...state,
        userRolesAccess: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_USERACCESS_SUCCESS:
    case CONSTANTS.SEARCH_USERACCESS_FAILURE:
      return {
        ...state,
        userAccess: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_GROUP_SUCCESS:
    case CONSTANTS.SEARCH_STORE_GROUP_FAILURE:
      return {
        ...state,
        storeGroups: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_SUCCESS:
    //case CONSTANTS.SEARCH_STORE_FAILURE:
      return {
        ...state,
        stores: data || [],
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.STORESEARCH_STORE_SUCCESS:
    case CONSTANTS.STORESEARCH_STORE_FAILURE:
      return {
        ...state,
        storeSearchStores: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_COMPANY_SUCCESS:
    case CONSTANTS.SEARCH_COMPANY_FAILURE:
      return {
        ...state,
        companies: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_COUNTRY_SUCCESS:
    case CONSTANTS.SEARCH_COUNTRY_FAILURE:
      return {
        ...state,
        countries: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_CITY_SUCCESS:
    case CONSTANTS.SEARCH_CITY_FAILURE:
      return {
        ...state,
        cities: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PROMOTIONS_SUCCESS:
    case CONSTANTS.SEARCH_PROMOTIONS_FAILURE:
      return {
        ...state,
        promotions: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PROMOTIONS_PGROUP_SUCCESS:
    case CONSTANTS.SEARCH_PROMOTIONS_PGROUP_FAILURE:
      return {
        ...state,
        promotionspurchasegroups: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PROMOTIONS_TGROUP_SUCCESS:
    case CONSTANTS.SEARCH_PROMOTIONS_TGROUP_FAILURE:
      return {
        ...state,
        promotionstargetgroups: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS:
    case CONSTANTS.SEARCH_STORE_DEVICES_FAILURE:
      return {
        ...state,
        storeDevices: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_CUST_DISPLAY_SUCCESS:
    case CONSTANTS.SEARCH_CUST_DISPLAY_FAILURE:
      return {
        ...state,
        customerDisplays: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_VIRTUAL_PRINTERS_SUCCESS:
    case CONSTANTS.SEARCH_VIRTUAL_PRINTERS_FAILURE:
      return {
        ...state,
        virtualPrinters: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS:
    case CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE:
      return {
        ...state,
        storeTerminals: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS_SILENT:
    case CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE_SILENT:
      return {
        ...state,
        storeTerminals: data,
        apiPending: false,
        errormsg: '', //supress/silent the error
        servererror: '' //silent the error
      }
    case CONSTANTS.SEARCH_STORE_TAGS_SUCCESS_SILENT:
      case CONSTANTS.SEARCH_STORE_TAGS_FAILURE_SILENT:
        return {
          ...state,
          storeTags: data,
          apiPending: false,
          errormsg: '', //surpress/silent the error
          servererror: '' //silent the error
        }
    case CONSTANTS.PERIPHERALSEARCH_DEVICES_SUCCESS:
    case CONSTANTS.PERIPHERALSEARCH_DEVICES_FAILURE:
      return {
        ...state,
        peripheralSearchDevices: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_STORE_TAGS_SUCCESS:
    case CONSTANTS.SEARCH_STORE_TAGS_FAILURE:
      return {
        ...state,
        storeTags: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_API_LOGS_SUCCESS:
    case CONSTANTS.SEARCH_API_LOGS_FAILURE:
        return {
          ...state,
          apilogs: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        }; 
    case CONSTANTS.SEARCH_EIP_MESSAGE_SUCCESS:
      case CONSTANTS.SEARCH_EIP_MESSAGE_FAILURE:
          return {
            ...state,
            eipMessages: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ""
          }; 
    case CONSTANTS.SEARCH_SALE_RETURN_SUCCESS:
      case CONSTANTS.SEARCH_SALE_RETURN_FAILURE:
          return {
            ...state,
            saleReturnList: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ""
          }; 
      case CONSTANTS.SEARCH_CART_SYNC_SUCCESS:
        case CONSTANTS.SEARCH_CART_SYNC_FALIURE:
            return {
              ...state,
              cartSyncList: data,
              apiPending: false,
              errormsg: errormsg,
              servererror: servererror || ""
            }; 
    case CONSTANTS.SEARCH_API_LOGS_BY_ID_SUCCESS:
    case CONSTANTS.SEARCH_API_LOGS_BY_ID_FAILURE:
      return {
        ...state,
        apilog: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS:
    case CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE:
      return {
        ...state,
        jobtasklogs: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      }; 
    case CONSTANTS.SEARCH_DATASYNC_LOGS_SUCCESS:
      case CONSTANTS.SEARCH_DATASYNC_LOGS_FAILURE:
          return {
            ...state,
            datasynclogs: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ""
          }; 
      case CONSTANTS.SEARCH_DATASYNC_LOGS_BY_ID_SUCCESS:
      case CONSTANTS.SEARCH_DATASYNC_LOGS_BY_ID_FAILURE:
        return {
          ...state,
          datasynclog: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        };
    case CONSTANTS.SEARCH_EXPORTED_COUPON_TRANSACTIONS_SUCCESS:
    case CONSTANTS.SEARCH_EXPORTED_COUPON_TRANSACTIONS_FAILURE:
        return {
          ...state,
          coupontrxexports: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        }; 
    case CONSTANTS.EXPORT_COUPON_TRANSACTIONS_SUCCESS:
    case CONSTANTS.EXPORT_COUPON_TRANSACTIONS_FAILURE:
      return {
        ...state,
        couponexport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      }; 
    case CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID_SUCCESS:
    case CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID_FAILURE:
      return {
        ...state,
        jobtasklog: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.STORE_PRODUCT_SEARCH_SUCCESS:
    case CONSTANTS.STORE_PRODUCT_SEARCH_FAILURE:
      return {
        ...state,
        storeproducts: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.COUPON_TRX_SEARCH_SUCCESS:
    case CONSTANTS.COUPON_TRX_SEARCH_FAILURE:
    case CONSTANTS.SEARCH_COUPON_TRX_SUCCESS:
    case CONSTANTS.SEARCH_COUPON_TRX_FAILURE:
      return {
        ...state,
        couponTrxs: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.SALES_DETAILS_REPORT_SUCCESS:
    case CONSTANTS.SALES_DETAILS_REPORT_FAILURE:
        return {
          ...state,
          salesDetailsReport: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        };
    case CONSTANTS.POS_MASTER_DETAILS_REPORT_SUCCESS:
    case CONSTANTS.POS_MASTER_DETAILS_REPORT_FAILURE:
        return {
          ...state,
          posMasterDetailsReport: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        };
    case CONSTANTS.TENDER_LISTING_REPORT_SUCCESS:
    case CONSTANTS.TENDER_LISTING_REPORT_FAILURE:
        return {
          ...state,
          tenderListingReport: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        };
    case CONSTANTS.REFUND_DETAILS_REPORT_SUCCESS:
    case CONSTANTS.REFUND_DETAILS_REPORT_FAILURE:
      return {
        ...state,
        refundDetailsReport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.TAG_DETAILS_REPORT_SUCCESS:
    case CONSTANTS.TAG_DETAILS_REPORT_FAILURE:
      return {
        ...state,
        tagDetailsReport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
    case CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT_SUCCESS:
    case CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT_FAILURE:
      return {
        ...state,
        productPriceDetailsReport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      };
      case CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT_SUCCESS:
      case CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT_FAILURE:
        return {
          ...state,
          productSalesSummaryReport: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        };
    case CONSTANTS.SEARCH_PROP_CONFIG_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_FAILURE:
      return {
        ...state,
        propConfigs: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ''
      }
    case CONSTANTS.SEARCH_STORE_OPEN_SUCCESS_SILENT:
    case CONSTANTS.SEARCH_STORE_OPEN_FAILURE_SILENT:
      return {
        ...state,
        storeSessions: data,
        apiPending: false,
        errormsg: '', //supress/silent the error
        servererror: '' //silent the error
      }
    case CONSTANTS.EXPORT_REPORT_SUCCESS:
    case CONSTANTS.EXPORT_REPORT_FAILURE:
      return {
        ...state,
        reportexport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      }; 
    case CONSTANTS.EXPORT_EJT_SUCCESS:
    case CONSTANTS.EXPORT_EJT_FAILURE:
      return {
        ...state,
        ejtexport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      };
    case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXTYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXTYPES_FAILURE:
        return {
          ...state,
          masterTrxTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_EJ_TRXTYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_EJ_TRXTYPES_FAILURE:
        return {
          ...state,
          ejTrxTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_EJ_BY_FIELDS_SUCCESS:
    case CONSTANTS.SEARCH_EJ_BY_FIELDS_FAILURE:
        return {
          ...state,
          ej: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_PAYSTATUS_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_PAYSTATUS_FAILURE:
        return {
          ...state,
          payStatus: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_SALES_TRXTYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_SALES_TRXTYPES_FAILURE:
        return {
          ...state,
          salesTrxTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
      case CONSTANTS.SEARCH_PROP_CONFIG_JOBTASKTYPES_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_JOBTASKTYPES_FAILURE:
          return {
            ...state,
            jobTaskTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
      case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_FAILURE:
          return {
            ...state,
            masterTrxGroups: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
      case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE:
          return {
            ...state,
            masterTrxGrpTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }

      case CONSTANTS.SEARCH_PROP_CONFIG_COLLECTOR_TYPES_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_COLLECTOR_TYPES_FAILURE:
            return {
              ...state,
              collectorUsers: data,
              apiPending: false,
              errormsg: errormsg,
              servererror: servererror || ''
            }
        case CONSTANTS.SEARCH_PROP_CONFIG_POINTRULETYPES_SUCCESS:
          case CONSTANTS.SEARCH_PROP_CONFIG_POINTRULETYPES_FAILURE:
              return {
                ...state,
                rabbitPointRuleTypes: data,
                apiPending: false,
                errormsg: errormsg,
                servererror: servererror || ''
              }
      case CONSTANTS.SEARCH_PROP_CONFIG_REFUNDTYPES_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_REFUNDTYPES_FAILURE:
          return {
            ...state,
            refundTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
      case CONSTANTS.SEARCH_PROP_CONFIG_PAYTYPES_SUCCESS:
      case CONSTANTS.SEARCH_PROP_CONFIG_PAYTYPES_FAILURE:
          return {
            ...state,
            payTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
    case CONSTANTS.SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS:
        return {
          ...state,
          tenderTagTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE:
        return {
          ...state,
          storeTenderGroupTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_DEVICE_VENDORTYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_DEVICE_VENDORTYPES_FAILURE:
        return {
          ...state,
          deviceVendorTypes: data.length > 0 ? data[0] : [],
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_SALE_INVOICE_PAYMENT_TYPES_SUCCESS:
    case CONSTANTS.SEARCH_SALE_INVOICE_PAYMENT_TYPES_FAILURE:
        return {
          ...state,
          paymentInvoiceList: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.SEARCH_PROP_CONFIG_TOPUPTAGTYPES_SUCCESS:
    case CONSTANTS.SEARCH_PROP_CONFIG_TOPUPTAGTYPES_FAILURE:
        return {
          ...state,
          topupTagTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    // tax configuration
    case CONSTANTS.SEARCH_TAXCONFIGURATION_SUCCESS:
      case CONSTANTS.SEARCH_TAXCONFIGURATION_FAILURE:
        return {
          ...state,
          taxentries: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
    // special discount
     // for getting pid for searchbyfields
    case CONSTANTS.SEARCH_SPECIALDISCOUNT_PID_SUCCESS:
      case CONSTANTS.SEARCH_SPECIALDISCOUNT_PID_FAILURE:
        return {
          ...state,
          specialdiscountpid: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
     // for getting results of general search
    case CONSTANTS.SEARCH_SPECIALDISCOUNT_SUCCESS:
      case CONSTANTS.SEARCH_SPECIALDISCOUNT_FAILURE:
        return {
          ...state,
          specialdiscounts: data.subcategories,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
     // for getting results of searchbyfields
    case CONSTANTS.SEARCH_SPECIALDISCOUNT_FIELDS_SUCCESS:
      case CONSTANTS.SEARCH_SPECIALDISCOUNT_FIELDS_FAILURE:
        return {
          ...state,
          specialdiscounts: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ""
        };
    case "RESET":
      return initialState;
    case "RESET_ERROR_ONLY":
      return { ...state, errormsg: '' }
    case CONSTANTS.SPECIAL_DISCOUNT_SEARCH_SUCCESS:
    case CONSTANTS.SPECIAL_DISCOUNT_SEARCH_FAILURE:
      return {
        ...state,
        specialDiscounts: data,
        apiPending: false,
        errormsg: '', //supress/silent the error
        servererror: '' //supress/silent the error
      };
      case CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_SUCCESS:
      case CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_FAILURE:
        return {
          ...state,
          storeBIRInfo: data,
          apiPending: false,
          errormsg: '', //supress/silent the error
          servererror: '' //supress/silent the error
        };
        case CONSTANTS.SEARCH_TERMINAL_BIRINFO_SILENT_SUCCESS:
        case CONSTANTS.SEARCH_TERMINAL_BIRINFO_SILENT_FAILURE:
          return {
            ...state,
            terminalBIRInfo: data,
            apiPending: false,
            errormsg: '', //supress/silent the error
            servererror: '' //supress/silent the error
          };
    case CONSTANTS.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_FAILURE:
    case CONSTANTS.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_SUCCESS:
        return {
          ...state,
          dataValidationTaskGroups: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
    case CONSTANTS.EXPORT_REPORT_SUCCESS:
    case CONSTANTS.EXPORT_REPORT_FAILURE:
      return {
        ...state,
        reportexport: data,
        apiPending: false,
        errormsg: errormsg,
        servererror: servererror || ""
      }; 
    case CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_SUCCESS:
    case CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_FAILURE:
        return {
          ...state,
          itemSalesTypes: data,
          apiPending: false,
          errormsg: errormsg,
          servererror: servererror || ''
        }
      case CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_SUCCESS:
      case CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_FAILURE:
          return {
            ...state,
            itemSalesTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
      case CONSTANTS.SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_SUCCESS:
      case CONSTANTS.SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_FAILURE:
          return {
            ...state,
            serviceReportTypes: data,
            apiPending: false,
            errormsg: errormsg,
            servererror: servererror || ''
          }
      case CONSTANTS.SPECIAL_DISCOUNT_SUMMARY_SUCCESS:
      case CONSTANTS.SPECIAL_DISCOUNT_SUMMARY_FAILURE:
          return {
            ...state,
            specialDiscountSummary: data,
            apiPending: false,
            errormsg: '', 
            servererror: ''
          };
      case CONSTANTS.SPECIAL_DISCOUNT_TYPE_SUCCESS:
      case CONSTANTS.SPECIAL_DISCOUNT_TYPE_FAILURE:
          return {
            ...state,
            specialDiscountTypeData: data,
            apiPending: false,
            errormsg: '', 
            servererror: ''
          };
      case "QUERY_TERMINAL_BIRINFO_RESET":
        return {
          ...state,
          terminalBIRInfo: []
        }
      case CONSTANTS.REPLENISHMENT_GET_ALL_SUCCESS:
      case CONSTANTS.REPLENISHMENT_GET_ALL_FAILURE:
          return {
            ...state,
            replenishmentList: data,
            apiPending: false,
            errormsg: '', 
            servererror: ''
          };
    default:
      return state;
  }
};
export default queryReducer;
