import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  crudReset,
  userRoleSearch,
  updateUserGroup,
  createUserGroup,
  userAccessSearch
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
// import CheckboxTree from "react-checkbox-tree";
import UserRoleAccessTree from '../UserRoles/UserRoleAccessTree';


class AddUserGroup extends React.PureComponent {

  companyid = localStorage.getItem("company_id")


  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      isView: false,
      // Formrelated
      groupcode: "",
      title: "",
      roles: "",
      status: "Active",
      // usergroups: [],
      value: "",
      //roles: [],//["Web Admin", "Admin"],
      // userroles: [],
      // selectedRoles: [],

      groupNameError: "",
      rolesError: "",
      formError: false,
      formErrorMsg: ""
    };
  }

  componentWillMount = () => {
    this.props.dispatch(userRoleSearch("status=Active"))
    this.props.dispatch(userAccessSearch("status=Active"))
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isView = this.props.location.state.isView;
      if (rec) {
          console.log('rec::', rec)
          this.setState({
            id: rec.id,
            groupcode: rec.groupcode,
            title: rec.title,
            roles: rec.roles,
            status: rec.status
          })
      }
      this.setState({isView, isEdit})
    }
  }

  _isEditMode = () => {
    return this.props.location
      && this.props.location.state
      && this.props.location.state.isEdit
  }

  _isViewMode = () => {
    return this.state.isView
  }

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _renderUserGroupDetails = () => {

    return (
      <React.Fragment>
        <div className="row sub-title">
          User Group Details
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Name
            </label>
            <input type="text" className="form-control" id="title"
              value={this.state.title}
              onChange={e => this.setState({ title: e.target.value })}
              disabled={this._isViewMode()}
            >
            </input>
          </div>
        </div>

      </React.Fragment>
    )
  }

  groupByModules = (userAccess) => {
    return userAccess.reduce((cache, access) => {
      const module = access.module
      if (module in cache) {
        return { ...cache, [module]: cache[module].concat(access) }
      }
      return { ...cache, [module]: [access] }
    }, {})
  }

  _renderRolesList = () => {
    const { userRoles } = this.props.queryData


    // console.log('userAccess::', userAccess)
    // console.log('groupOfModules::', groupOfModules)

    let existingSelectedRoles = []
    if (this._isEditMode() || this._isViewMode()) {
      existingSelectedRoles = this.state.roles.split(",")
    }

    const selectedRoles = []
    const nodes = []
    userRoles.forEach(e => {
      let node = {
        value: "",
        label: "",
        icon: '',
      };
      node.value = e.rolecode
      node.label = `${e.rolecode} - ${e.title}`

      if (existingSelectedRoles.includes(e.rolecode)) {
        selectedRoles.push(e.rolecode)
      }
      nodes.push(node)
    })

    console.log("nodes", nodes)

    
    

    return (
      <React.Fragment>
        <div className="row sub-title">
          Access Details
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            {/* <UserRoleAccessTree modules={this.modules} groupByModules={groupOfModules}/> */}


{/*
            <div className="customOl">
              <CheckboxTree
                nodes={nodes}
                checked={this.state.selectedRoles}
                expanded={this.state.expanded}
                onCheck={(selectedRoles) =>
                  this.setState({ selectedRoles }, () => {
                    // console.log(selectedRoles);
                  })
                }
                onExpand={(expanded) => this.setState({ expanded })}
                icons={{
                  check: <span className="rct-icon rct-icon-check" />
                }}
                nativeCheckboxes="true"
              />
            </div>
              */}

              <UserRoleAccessTree nodes={nodes} selectedRoles={selectedRoles} saveSelected={this.saveSelected}/>


          </div>
        </div>
      </React.Fragment>
    );
  }

  saveSelected = (e) => {
    console.log('selectedddd', e)
    // this.setState({selectedRoles: e})
    this.setState({roles: e.join(',')})
    // console.log('selectedRoles', this.state.selectedRoles)
    console.log('roles', this.state.roles)
  }

  // _renderRolesList2 = () => {
  //   const { userRoles } = this.props.queryData

  //   const { userAccess } = this.props.queryData


  //   const groupOfModules = this.groupByModules(userAccess)

  //   console.log('userAccess::', userAccess)
  //   console.log('groupOfModules::', groupOfModules)


  //   const nodes = []
  //   for (const [module, access] of Object.entries(groupOfModules)) {
  //     const children = []
  //     access.forEach(e => {
  //       // console.log("e=>", e)
  //       let child = {
  //         value: "",
  //         label: "",
  //         icon: '',
  //       };
  //       child.value = e.accesscode
  //       child.label = e.title
  //       // console.log('child=>', child)
  //       children.push(child)
  //     })
  //     // console.log('children=>', children)
  //     const node = { value: "", label: "", children: [], icon: '' }
  //     node.label = getModuleDesc(module)
  //     node.value = module
  //     node.children = children
  //     nodes.push(node)
  //   }

  //   console.log("nodes", nodes)


  //   return (
  //     <React.Fragment>
  //       <div className="row sub-title">
  //         Access Details
  //       </div>
  //       <div className="row">
  //         <div className="form-group col-lg-6 col-sm-12">
  //           {/* <UserRoleAccessTree modules={this.modules} groupByModules={groupOfModules}/> */}
  //           <div className="customOl">
  //             <CheckboxTree
  //               nodes={nodes}
  //               checked={this.state.selectedRoles}
  //               expanded={this.state.expanded}
  //               onCheck={(selectedRoles) =>
  //                 this.setState({ selectedRoles }, () => {
  //                   // console.log(selectedRoles);
  //                 })
  //               }
  //               onExpand={(expanded) => this.setState({ expanded })}
  //               icons={{
  //                 check: <span className="rct-icon rct-icon-check" />
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   );
  // }

  //   _renderRolesList1 = () => {
  //     // console.log('roles length::', this.state.roles.length)




  //     const { userAccess } = this.props.queryData
  //     console.log('userAccess:', userAccess)

  //     const { userRoles } = this.props.queryData
  //     this.setState(({ userroles: userRoles }))
  //     // console.log('roles:::', this.state.roles)

  //     // const { userRolesAccess } = this.props.queryData


  //     const groupOfModules = this.groupByModules(userAccess)


  //         console.log('groupOfModules::' + groupOfModules + "::")

  //     return (
  //       <React.Fragment>
  //         <div className="row sub-title">
  //           Roles
  //         </div>
  //         <div className="row">
  //           <div className="form-group col-lg-6 col-sm-12">
  //             {/* <ul> */}
  //               {/* {this.state.roles.map((element, i) => (
  //                 this.renderTreeNode(element, i, userAccess)
  //               ))} */}
  //               {/* {userRoles.map((element, i) => (

  //                 this.renderTreeNode(element, i, userAccess)
  //               ))} */}

  //               {/* {userRoles.forEach(e => {
  //                 console.log(JSON.stringify(e))

  //             // this.props.dispatch(userRoleAccessSearch(`status=Active&rolecode=${e.rolecode}`))
  //             // const { userRolesAccess  } =  this.props.queryData
  //             // console.log(JSON.stringify(userRolesAccess))

  //               })} */}
  //             {/* </ul> */}

  //             {this.renderTreeNode(userRoles, userAccess)}

  //       {/* <UserRoleAccessTree modules={modules} groupByModules={groupOfModules}/> */}

  //             {/* <ul>
  //         {["web admin", "admin"].map((e) => (
  //           <li>{e}</li>
  //         ))}
  // </ul> */}
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     )
  //   }


  renderUserRolesAccess(roles, userAccess) {

  }


  renderTreeNode = (role, i, userAccess) => {

    // console.log('role:', JSON.stringify(role))
    // return (
    //   <React.Fragment>
    //     {/* <UserRoleAccessTreeNode /> */}
    //     <li key={i} onClick={() => this.expandRoles(role.rolecode)} >{role.title}

    //       <UserRoleAccess rolecode={role.rolecode} userAccess={userAccess} />

    //     </li>
    //   </React.Fragment>
    // )
  }

  expandRoles = (rolecode) => {
    console.log("expand::", rolecode)
    //this.props.dispatch(userRoleAccessSearch(`rolecode=${rolecode}&status=Active`))
  }

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center">
              {this._renderUserGroupDetails()}
            </form>
            <form className="splitFrmDiv" style={{ marginTop: "1em" }}>
              {this._renderRolesList()}
            </form>
            {/* <form className="splitFrmDiv text-center" style ={{marginTop: "1em"}} >
              {this._renderRolesList()}
            </form> */}
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderMainButton = () => {
    if (this._isViewMode()) {
      return (
        <React.Fragment>
          <Link
            to="/ui/usergroups"
            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          >
            Close
          </Link>
        </React.Fragment>
      )
    }
    const isEditMode = this._isEditMode()
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createUserGroup}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createUserGroup}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/usergroups"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    )
  }

  _clearErrorMessages = () => {
    this.setState({
      storeidError: "",
      nameError: "",
      emailAddressError: "",
      userGroupError: "",
      userNameError: "",
      passwordError: "",
      formError: false,
      formErrorMsg: ""
    });
  };

  validateForm = () => {
    console.log('validating the form')
    this._clearErrorMessages()
    const {
      title
    } = this.state
    let valid = true
    if (title.trim() === "") {
      this.setState({ nameError: "Please enter a name" })
      console.log(this.state.nameError)
      valid = false;
    }

    return valid
  }

  getCreatePayload = () => {
    const {
      //groupcode,
      title,
      status
    } = this.state;

    let body = {
      //groupcode,
      title,
      roles: this.state.roles,
      status,
      companyid: this.companyid
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }

    const payload = {
      body: body
    };
    console.log(payload);
    return payload;
  };

  _createUserGroup = () => {
    if (this.validateForm()) {
      console.log('form validation success')
      if (this._isEditMode()) {
        this.props.dispatch(
          updateUserGroup(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createUserGroup(this.getCreatePayload()));
      }
    } else {
      console.log('form validation failed')
      this.setState({
        formError: true,
        formErrorMsg: "Please clear all the errors to continue !!!"
      });
      window.scrollTo(0, 0);
    }
  };

  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
    // this.props.dispatch(productArticleReset())
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() ? "Edit User Group" : this._isViewMode() ? "View User Group" : "Add User Group"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/usergroups"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "User Group updated successfully"
                  : "User Group created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};



// const UserRoleAccessTreeNode = ({ node, groups }) => {

//   const [childVisible, setChildVisiblity] = useState(false);

//   const hasChild = groups !== undefined && groups.length > 0
//   var children = []
//   if (hasChild) {
//     children = groups.forEach(e => {
//       return <li>{e.title}</li>
//     })
//   }

//   console.log("groups::" , groups)
//   console.log("children", children)

//   return (
//     // <li className="d-tree-node border-0">
//     <li>
//       {/* <div className="d-flex" onClick={(e) => setChildVisiblity((v) => !v)}> */}
//       <div>
//         {hasChild && (
//           <div
//             className={`d-inline d-tree-toggler ${
//               childVisible ? "active" : ""
//             }`}
//           >       
//           </div>
//         )}
//         <div> {node} </div>
//       </div>

//       {hasChild && (
//         <div>
//           <ul>
//             {groups.map((element) => 
//               <li key={element.title}> {element.title} </li>
//             )}
//           </ul>
//         </div>
//       )}
//     </li>
//   );
// };

export default connect(mapStateToProps)(AddUserGroup);

