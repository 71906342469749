import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import TenantCode from "./../../../components/TenantCode";
import { searchFields } from "./tableCols";
import {
    deleteDeliveryArea,
    unpublishArticleInfoApi,
    publisArticleInfoApi,
    crudReset,
    searchGeneral,
    deliverycountryConfig,
    deliverytypeConfig,
    resetAction,
    createDeliveryArea,
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleSearcher from "../../../components/SimpleSearcher";
import SimpleTable from "../../../components/SimpleTable";
import SimpleSelect from "../../../components/SimpleSelect";
import StatusComp from "../../../components/StatusComp";
import Modal from "react-awesome-modal";
import { canDelete, canWrite, permissions } from "../../../helpers/Util";
import { Button } from "primereact/button";
import RefreshIcon from "../../../components/ImageComponents/RefreshIcon";
import PrimeTable from "../../../components/PrimeTable";

class DisplayDeliveryArea extends React.PureComponent {
    constructor(props) {
        super(props);

        this.group = "DE_SOP_DA";
        this.module = "SOP";
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 0,
            status: "Active",
            areacode: "",
            city: "",
            town: "",
            title: "",
            openDeleteprompt: false,
            delete_rec_id: "",
            message: "",
            search_field: "status",
            search_condi: "like",
            search_value: "",
            showSearchDiv: false,
            deliverytype: "",
            storeid: "",
            status: "Active"
        };
    }

    componentWillMount = () => {
        const { storeid } = this.state;
        this.props.dispatch(
            deliverycountryConfig({
                body: { status: "Active", propgroup: "Country", storeid },
            }),
        );
        this.props.dispatch(
            deliverytypeConfig({
                body: {
                    language: "en",
                    propgroup: "OPS",
                    propvalue: "Delivery",
                },
            }),
        );
        this.callQueryApiForFetch("ONLOAD");
        this.allPermissions = permissions(this.module, this.group);
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status !== "Deleted" && canWrite(this.allPermissions)) {
            return (
                <span>
                    <Link
                        to={{
                            pathname: "/ui/delivery-area/addNew",
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>

                    {canDelete(this.allPermissions) && (
                        <img
                            title="Delete"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                            onClick={() => this.deleteStoreInfo(row)}
                        />
                    )}
                    <img
                        title="Copy"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        onClick={() => this.copyRecord(row)}
                    />
                </span>
            );
        }
    };

    copyRecord = (row) => {
        this.setState({ message: "Item Copied Suucessfully" });
        this.props.dispatch(createDeliveryArea(this.getCreatePayload(row)));
    };

    getCreatePayload = (row) => {
        let arcode = "AR" + Math.floor(Math.random() * 899999 + 100000);
        const requestdata = {
            body: {
                ...row,
                title: "[COPY-" + row.areacode + "]" + row.title,
                areacode: arcode.toString(),
            },
        };
        return requestdata;
    };

    // API Calls
    createFetchUrl = () => {
        const { search_field, search_condi, search_value } = this.state;

        const requestBody = {
            body: {
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                "ops/v1/delivery/area/search",
                this.createFetchUrl(fetchType),
                "SEARCH_ARTICLE_INFO_SUCCESS",
                "SEARCH_ARTICLE_INFO_FAILURE",
            ),
        );
    };

    callFetchFields = () => {
        this.props.dispatch(
            searchGeneral(
                "ops/v1/delivery/area/search/fields",
                this.createFetchFields(),
                "SEARCH_ARTICLE_INFO_SUCCESS",
                "SEARCH_ARTICLE_INFO_FAILURE",
            ),
        );
    };

    createFetchFields = () => {
        const { storeid, title, areacode, city, town, status, deliverytype } =
            this.state;
        const requestBody = {
            body: {
                storeid,
                title,
                areacode,
                city,
                town,
                status,
                deliverytype,
            },
        };
        return requestBody;
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case "ONLOAD":
                this.callFetch("ONLOAD");
                break;
            case "SEARCH":
                this.callFetch("SEARCH");
                break;
            case "SEARCH_FIELDS":
                this.callFetchFields();
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === "id") val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch("SEARCH");
            },
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteDeliveryArea(this.state.delete_rec_id));
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch("ONLOAD");
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    deleteStoreInfo = (rowData) => {
        this.setState({ message: "Delivery Area deleted successfully!" });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };
    unpublishArticle = (rowData) => {
        this.setState({ message: "Delivery Area unpublished successfully!" });
        this.props.dispatch(unpublishArticleInfoApi(rowData.id));
    };
    publishArticle = (rowData) => {
        this.setState({ message: "Delivery Area published successfully!" });
        this.props.dispatch(publisArticleInfoApi(rowData.id));
    };

    _renderSearchModal = () => {
        return (
            <Modal
                visible={this.state.showSearchDiv}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSearchDiv()}
            >
                <div className="modalForm queryFormModal">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            Operation Slot Search
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this._closeSearchDiv}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderSearchForm()}
                </div>
            </Modal>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, () => {});
    };

    getObjectList = (myObj) => {
        let modifiedObj = myObj;
        if (Array.isArray(modifiedObj.additionalfields)) {
            let objFields = {};
            modifiedObj.additionalfields.forEach(
                (field) => (objFields[field] = field),
            );
            modifiedObj.additionalfields = objFields;
            return modifiedObj;
        } else {
            return Object.assign({}, ...myObj.map((e) => e.additionalfields));
        }
    };

    _renderSearchForm = () => {
        const { deliverytypeList } = this.props.queryData;
        let dlist = this.getObjectList(deliverytypeList);

        const { deliverycountryList } = this.props.queryData;
        let cnlist = deliverycountryList.map((e) => ({
            title: e.propvalue,
            value: e.propvalue,
        }));
        let citylist = Object.assign(
            {},
            ...deliverycountryList.map((e) => e.additionalfields),
        );

        //let dlist = Object.assign({}, ...deliverytypeList.map(e=>(e.additionalfields)));
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="deliverytype" className="floatLeft">
                            Delivery Type
                        </label>
                        <SimpleSelect
                            objArray={dlist}
                            callbackChangeFilter={(value) =>
                                this.setState({ deliverytype: value })
                            }
                            selectedOption={this.state.deliverytype}
                            id="deliverytype"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="areacode" className="floatLeft">
                            Areacode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="areacode"
                            value={this.state.areacode}
                            onChange={(event) =>
                                this.setState({ areacode: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="areacode" className="floatLeft">
                            City
                        </label>
                        <SimpleSelect
                            objArray={citylist}
                            callbackChangeFilter={(value) =>
                                this.setState({ city: value })
                            }
                            selectedOption={this.state.city}
                            id="city"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="areacode" className="floatLeft">
                            Town
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="town"
                            value={this.state.town}
                            onChange={(event) =>
                                this.setState({ town: event.target.value })
                            }
                        />
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="status" className="floatLeft">
                                Status
                            </label>
                            <StatusComp
                                selectedOption={this.state.status}
                                callbackChangeFilter={(value) => {
                                    this.setState({ status: value });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.callQueryApiForFetch("SEARCH_FIELDS");
                                this._closeSearchDiv();
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this._closeSearchDiv();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </>
        );
    };

    _openSearchDiv = () => {
        this.setState({ showSearchDiv: true });
    };
    _closeSearchDiv = () => {
        this.setState({ showSearchDiv: false });
    };

    render() {
        const {
            articleFlowData,
            errormsg: queryError,
            languageConfig,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = "";
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
        if (this.props.queryData.apiPending === true) {
            return <Loading />;
        }

        const { history } = this.props;

        const columns = [
            { field: "areacode", header: "Area Code", sortable: true },
            { field: "title", header: "Title", sortable: true },
            { field: "storeid", header: "Store Id", sortable: true },
            { field: "deliverytype", header: "Delivery Type", sortable: true },
            {
                field: "deliveryprice",
                header: "Delivery Price",
                sortable: true,
            },
            { field: "sortorder", header: "Sort", sortable: true },
        ];

        const actionTemplate = (row) => {
            return (
                <span>
                    <img
                        title="Edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        onClick={() =>
                            history.push("/ui/delivery-area/configure", {
                                item: row,
                                isEdit: true,
                            })
                        }
                    />
                    <img
                        title="Copy"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        onClick={() => this.copyRecord(row)}
                    />
                </span>
            );
        };

        return (
            <>
                {this.state.showSearchDiv && this._renderSearchModal()}
                <div className="form_height">
                    <div className="flex px-4 mt-4">
                        <h2
                            className="text-3xl font-normal"
                            style={{ color: "#212121" }}
                        >
                            Delivery Area
                            <RefreshIcon
                                handleClick={() =>
                                    this.callQueryApiForFetch("ONLOAD")
                                }
                            />
                        </h2>
                        <div className="ml-auto">
                            {/* <Button
                                label="Advanced Filter"
                                icon="pi pi-filter"
                                severity="secondary"
                                outlined
                                onClick={() =>
                                    this.setState({ showSearchDiv: true })
                                }
                            /> */}
                            {canWrite(this.allPermissions) && (
                                <Button
                                    icon="pi pi-plus"
                                    label="Add New"
                                    severity="info"
                                    outlined
                                    className="ml-2"
                                    onClick={() =>
                                        history.push(
                                            "/ui/delivery-area/configure",
                                        )
                                    }
                                />
                            )}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 m-4">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 noPadding noMargin"></div>
                                    <div className="col-lg-4 col-sm-12 noPadding noMargin">
                                        <SimpleSearcher
                                            filterOptions={searchFields}
                                            setAndSearch={this.searchQuery}
                                            search_condi={
                                                this.state.search_condi
                                            }
                                            search_value={
                                                this.state.search_value
                                            }
                                            search_field={
                                                this.state.search_field
                                            }
                                            //searchValues={statusValues}
                                        ></SimpleSearcher>
                                    </div>
                                </div>
                                <div className="title_devide" />
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    list={articleFlowData}
                                    columns={columns}
                                    actionColumn={actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.callQueryApiForFetch("ONLOAD");
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.callQueryApiForFetch("ONLOAD");
                                    }}
                                />
                            </div>

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this delivery
                                area?
                                <div style={{ margin: "2em" }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: "2em", color: "green" }
                                        : { padding: "2em", color: "red" }
                                }
                            >
                                {isSuccess ? this.state.message : ""}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: "2em",
                                              color: "red",
                                              fontWeight: "500",
                                          }
                                        : { padding: "2em", color: "red" }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ""}
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayDeliveryArea);
