import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    crudReset,
    storeTagsSearchByFields,
    storeDevicesSearchByFields,
    storeTerminalsSearchByFields,
    updateStoreTerminal,
    createStoreTerminal,
    createStoreTag,
    updateStoreTag
} from "../../../../actions/actions";

import ConfirmAlert from "../../../../components/ConfirmAlert";
import StatusOptionComp from "../../../../components/StatusOptionComp";
import { produce } from "immer";
import ReactSwitch from "react-switch";
import MultiSelect from "../../../../components/MultiSelect";
import {
    SEARCH_STORE_TERMINALS_FAILURE_SILENT,
    SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
} from "../../../../constants/constants";
import DatePicker from "react-date-picker";
import SimpleDropdown from "../../../../components/SimpleDropdown";
import { formatDate } from "../../../../helpers/Util";
import * as CONSTANTS from "../../../../constants/constants";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import DxMultiSelect from "../../../../components/SelectorComponents/DxMultiSelect";

class AddStoreTerminal extends React.PureComponent {
    editModeStatus = [
        { name: "Select", id: "" },
        { name: "New", id: "New" },
        { name: "Active", id: "Active" },
        { name: "InActive", id: "InActive" },
    ];

    // Dropdown options for bir activate
    birActivateOptions = [
        { name: "Select", id: "" },
        { name: "Yes", id: "Yes" },
        { name: "No", id: "No" },
    ];

    allPeripherals =
        "eft,printer,customerdisplay,virtualprinter,cashdrawer,light";
    devicegroup = "terminal";

    constructor(props) {
        super(props);

        //this.biraccreditedinitialstatus = false
        this.pendingbirconfigsave = false;
        this.biraccreditedupdated = {};
        //const isEdit = this.props.location.state.isEdit;
        const { location } = this.props;
        const isEdit = location?.state?.isEdit || false;
        const isView = location?.state?.isView || false;
        //const isView = this.props.location.state.isView;
        this.state = {
            id: "",
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: "",
            terminalid: "",
            deviceid: "",
            // devicegroup: 'terminal',
            brokerid: "",
            devicestatus: "",
            provider: "",
            title: "",
            excludedtenders: "",
            storetenders: [],
            storedevices: [],
            custdisplays: [],
            virtualprinters: [],
            posperipherals: "",

            // bir related
            // BIR related
            taggroup: "tprops",
            tagtype: "birinfo",
            tagtypevalue: "terminal_birinfo",
            birid: "",
            birenabled: false,
            biradditionalfields: "",
            min: "",
            serialno: "",
            ptu: "",
            ptudateissued: new Date(),
            ptudateuntil: new Date(),
            activate: "",

            virtualprinter: false,
            enablemember: isEdit || isView ? false : false,
            webpos: isEdit || isView ? false : false,
            customerdisplay: false,
            status: "New",
            additionalFields: {},
            storelabel: "",
            // Errors
            titleerror: "",
            terminaliderror: "",
            storeiderror: "",
            posperipheralserror: "",
            posiderror: "",
            virtualprintererror: "",
            custdisplayerror: "",
            invallocationid: "",

            terminaltype: "pos", //default
            posid: "",
            vpos: false,
            printreceipt: false,
            saleChannelArr: [
                {
                    name: "dxpv0",
                    code: "dxpv0",
                },
                {
                    name: "dxpmob",
                    code: "dxpmob",
                },
                {
                    name: "dxpkiosk",
                    code: "dxpkiosk",
                },
            ],
            saleschannel: "",
        };
    }

    handleCustomerDisplaySwitch = (customerdisplay) =>
        this.setState({ customerdisplay });

    handlePrintReceipt = (printreceipt) => this.setState({ printreceipt });

    handleVirtualPrinterSwitch = (virtualprinter) =>
        this.setState({ virtualprinter });

    handleEnableMemberSwitch = (enablemember) => {
        this.setState({ enablemember });
    };

    handleWebPosSwitch = (webpos) => {
        this.setState({ webpos });
    };

    handleTerminalTypeSelection = (e) => {
        this.setState({ terminaltype: e.target.value });
    };

    getAdditionalFieldsBIR = () => {
        return {
            birinfo: {
                min: this.biraccreditedupdated.min,
                serialno: this.biraccreditedupdated.serialno,
                ptu: this.biraccreditedupdated.ptu,
                ptudateissued: this.biraccreditedupdated.ptudateissued,
                ptudateuntil: this.biraccreditedupdated.ptudateuntil,
                activate: this.biraccreditedupdated.activate,
            },
        };
    };

    getCreatePayloadBIR = () => {
        const {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
        } = this.state;

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
            additionalfields: this.getAdditionalFieldsBIR(),
        };

        if (this.state.birid !== undefined && this.state.birid != "") {
            body["id"] = this.state.birid;
        }

        const payload = {
            body: body,
        };
        return payload;
    };

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            status,
            customerdisplay,
            terminaltype,
            posid,
            excludedtenders,
            posperipherals,
            virtualprinter,
            enablemember,
            webpos,
        } = this.state;
        let additionalfields = {
            customerdisplay: customerdisplay ? "Y" : "N",
            terminaltype,
            posid,
            excludedtenders:
                excludedtenders === ""
                    ? excludedtenders
                    : excludedtenders.join(","),
            posperipherals:
                posperipherals.length > 0 ? posperipherals.join(",") : "",
            virtualprinter: virtualprinter ? "Y" : "N",
            enablemember: enablemember ? "Y" : "N",
            webpos: webpos ? "Y" : "N",
            invallocationid: this.state.invallocationid,
            terminalsubtype: this.state.vpos ? "vpos" : "",
            printreceipt: this.state.printreceipt ? "Y" : "N",
            saleschannel: this.state.saleschannel
        };

        let body = {
            storeid,
            terminalid,
            title,
            deviceid: this._getDeviceId(),
            devicegroup: this.devicegroup,
            status,
            additionalfields,
        };

        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    _getDeviceId = () => {
        if (this.state.deviceid !== "") {
            return this.state.deviceid;
        }
        return "T" + Math.floor(Math.random() * 899999 + 100000);
    };

    _saveTerminal = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateForm()) {
            this.pendingbirconfigsave = false;

            if (window.config.BIR_ENABLED) {
                if (this.state.birenabled) {
                    this.pendingbirconfigsave = true;
                    this.biraccreditedupdated = {
                        min: this.state.min,
                        serialno: this.state.serialno,
                        ptu: this.state.ptu,
                        ptudateissued: formatDate(this.state.ptudateissued),
                        ptudateuntil: formatDate(this.state.ptudateuntil),
                        activate: this.state.activate,
                    };
                }
            }

            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreTerminal(this.getCreatePayload(), this.state.id),
                );
            } else {
                this.props.dispatch(
                    createStoreTerminal(this.getCreatePayload()),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue",
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            titleerror: "",
            // terminaliderror: ""
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { title, terminalid, storeid, terminaliderror, posid } =
            this.state;
        let valid = true;
        if (title.trim() === "") {
            this.setState({ titleerror: "Please enter a title" });
            valid = false;
        }

        if (terminalid.trim() === "") {
            this.setState({ terminaliderror: "Please enter terminal id" });
            valid = false;
        } else if (terminaliderror !== "") {
            valid = false;
        }

        if (storeid.trim() === "") {
            this.setState({ storeiderror: "Please enter storeid" });
            valid = false;
        }

        if (posid === "") {
            this.setState({
                posiderror: "Please enter posid",
            });
            valid = false;
        }

        return valid;
    };

    checkTerminalId = () => {
        const { storeid, terminalid } = this.state;

        if (terminalid === "") {
            this.setState({ terminaliderror: "" });
            return;
        }

        const queryParams = [];
        queryParams.push(`storeid=${storeid}`);
        queryParams.push(`terminalid=${terminalid}`);
        queryParams.push(`devicegroup=terminal`);
        this.props.dispatch(
            storeTerminalsSearchByFields(
                queryParams.join("&"),
                SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
                SEARCH_STORE_TERMINALS_FAILURE_SILENT,
            ),
        );
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _updateBIRFields = (birItem) => {
        const { additionalfields } = birItem;
        if (this.state.birenabled) {
            this.setState({
                biradditionalfields: additionalfields ? additionalfields : "",
                min: additionalfields ? additionalfields["birinfo"]["min"] : "",
                serialno: additionalfields
                    ? additionalfields["birinfo"]["serialno"]
                    : "",
                ptu: additionalfields ? additionalfields["birinfo"]["ptu"] : "",
                ptudateissued: additionalfields
                    ? this.toFormatDate(
                          additionalfields["birinfo"]["ptudateissued"],
                      )
                    : new Date(),
                ptudateuntil: additionalfields
                    ? this.toFormatDate(
                          additionalfields["birinfo"]["ptudateuntil"],
                      )
                    : new Date(),
                activate: additionalfields
                    ? additionalfields["birinfo"]["activate"]
                    : "",
            });
        }
    };

    _updateBIRInfo = (birItem) => {
        const { additionalfields } = birItem;

        this.setState(
            {
                birid: birItem["id"] ? birItem["id"] : null,
                biradditionalfields: additionalfields ? additionalfields : "",
            },
            () => {
                this._updateBIRFields(birItem);
            },
        );
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <Link
                    to="/ui/terminals"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Close
                </Link>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => {
                        this._saveTerminal(e);
                    }}
                >
                    {isEditMode ? "Update" : "Save"}
                </button>

                <Link
                    to="/ui/terminals"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    _renderBasicDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="terminalid"
                            className="floatLeft required"
                        >
                            Terminal Id
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="terminalid"
                            value={this.state.terminalid}
                            onChange={this.handleChangeTerminalId}
                            min="1"
                            max="999"
                            maxLength="3"
                            disabled={this._isEditMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.terminaliderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="terminalid" className="floatLeft">
                            Inventory Allocation Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="terminalid"
                            value={this.state.invallocationid}
                            onChange={(e) => {
                                this.setState({
                                    invallocationid: e.target.value,
                                });
                            }}
                            //disabled={this._isEditMode()}
                        />
                        {/* <InputText
                            className="w-100"
                            id="invallocationid"
                            value={this.state.invallocationid}
                            onChange={(event) => {
                                this.setState({
                                    invallocationid: event.target.value,
                                });
                            }}
                        /> */}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    handleChangeTerminalId = (event) => {
        const inputValue = event.target.value;

        if (inputValue.length <= 3) {
            this.setState({ terminalid: event.target.value }, () => {
                this.checkTerminalId();
            });
        }
    };

    _renderBirSection = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">BIR</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="activate" className="floatLeft">
                            Activate
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="activate"
                                value={this.state.activate}
                                disabled={true}
                            />
                        ) : (
                            <SimpleDropdown
                                id="activate"
                                listoptions={this.birActivateOptions}
                                selectedOption={this.state.activate}
                                callbackChangeFilter={(value) => {
                                    this.setState({ activate: value });
                                }}
                            />
                        )}
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="min" className="floatLeft">
                            MIN
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="min"
                            value={this.state.min}
                            onChange={(event) =>
                                this.setState({ min: event.target.value })
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="serialno" className="floatLeft">
                            Serial #
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="serialno"
                            value={this.state.serialno}
                            onChange={(event) =>
                                this.setState({ serialno: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="ptu" className="floatLeft">
                            PTU
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="ptu"
                            value={this.state.ptu}
                            onChange={(event) =>
                                this.setState({ ptu: event.target.value })
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="ptudateissued" className="floatLeft">
                            PTU Date Issued
                        </label>
                        <DatePicker
                            onChange={(value) => {
                                this.setState({ ptudateissued: value });
                            }}
                            value={this.state.ptudateissued}
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="ptudateuntil" className="floatLeft">
                            PTU Date Until
                        </label>
                        <DatePicker
                            minDate={this.state.ptudateissued}
                            onChange={(value) => {
                                this.setState({ ptudateuntil: value });
                            }}
                            value={this.state.ptudateuntil}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    handleChangeOptions = values => {
        this.setState({ saleschannel: values })

    }

    _renderSettings = () => {
        const { terminaltype } = this.state;
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Settings</div>

                <div className="ml-3 mb-4">
                    <label
                        htmlFor="terminaltype"
                        className="w-full required mb-1"
                    >
                        Terminal Type
                    </label>
                    <div className="flex w-full my-2">
                        <RadioButton
                            value="pos"
                            label="POS"
                            terminaltype={terminaltype}
                            handleSelection={this.handleTerminalTypeSelection}
                        />
                        <RadioButton
                            value="sco"
                            label="SCO"
                            terminaltype={terminaltype}
                            handleSelection={this.handleTerminalTypeSelection}
                        />
                        <RadioButton
                            value="kiosk"
                            label="Kiosk"
                            terminaltype={terminaltype}
                            handleSelection={this.handleTerminalTypeSelection}
                        />
                        <RadioButton
                            value="storeserver"
                            label="Store Server"
                            terminaltype={terminaltype}
                            handleSelection={this.handleTerminalTypeSelection}
                        />
                        <RadioButton
                            value="spm"
                            label="SPM (Special Purpose Machine) "
                            terminaltype={terminaltype}
                            handleSelection={this.handleTerminalTypeSelection}
                        />
                    </div>
                </div>

                <div className="ml-3 mb-4 flex mr-4">
                    <div className="flex-1">
                        <label htmlFor="terminaltype" className="w-full mb-1">
                            Terminal Subtype
                        </label>
                        <div className="flex w-full my-2">
                            <Checkbox
                                onChange={(e) =>
                                    this.setState({ vpos: e.checked })
                                }
                                checked={this.state.vpos}
                            ></Checkbox>
                            <label htmlFor="vpos" className="ml-2">
                                vpos
                            </label>
                        </div>
                    </div>
                    {this.state.vpos && (
                        <>
                            <div className="ml-4 flex-1">
                                <label
                                    htmlFor="printreceipt"
                                    className="w-full mb-1"
                                >
                                    Print Receipt
                                </label>
                                <div className="flex w-full my-2">
                                    <ReactSwitch
                                        checked={this.state.printreceipt}
                                        onChange={this.handlePrintReceipt}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="discount-tax-switch"
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="saleschannel"
                                    className="w-full mb-1"
                                >
                                    Sales Channel
                                </label>
                                <DxMultiSelect
                                    options={this.state.saleschannel}
                                    optionList={this.state.saleChannelArr}
                                    handleChangeOptions={this.handleChangeOptions}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="posid" className="floatLeft required">
                            Pos Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.posid}
                            onChange={(event) =>
                                this.setState({ posid: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.posiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="excltenders" className="floatLeft">
                            Excluded Tender(s)
                        </label>
                        <div className="row">
                            <MultiSelect
                                filterOptions={this.state.storetenders}
                                setSelectedFieldValues={(value) => {
                                    this.setState({ excludedtenders: value });
                                }}
                                id="excltenders"
                                itemsSelected={this.state.excludedtenders}
                                label="Select Tender To Exclude"
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft switch-label"
                        >
                            Customer Display
                        </label>
                        <ReactSwitch
                            checked={this.state.customerdisplay}
                            onChange={this.handleCustomerDisplaySwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft switch-label"
                        >
                            Allow Member Registration
                        </label>
                        <ReactSwitch
                            checked={this.state.enablemember}
                            onChange={this.handleEnableMemberSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft switch-label"
                        >
                            Virtual Printer
                        </label>
                        <ReactSwitch
                            checked={this.state.virtualprinter}
                            onChange={this.handleVirtualPrinterSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft switch-label"
                        >
                            Web Pos
                        </label>
                        <ReactSwitch
                            checked={this.state.webpos}
                            onChange={this.handleWebPosSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderPeripheralsSection = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Peripherals</div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="selecteddevices" className="floatLeft">
                            Select Peripherals
                        </label>
                        <div className="row">
                            <MultiSelect
                                filterOptions={this.state.storedevices}
                                setSelectedFieldValues={(value) => {
                                    this.setState({ posperipherals: value });
                                }}
                                id="selecteddevices"
                                itemsSelected={this.state.posperipherals}
                                label="Select Peripherals"
                            />
                            <div className="invalid-feedback">
                                {this.state.posperipheralserror}
                            </div>
                        </div>
                    </div>
                </div>
                {this._renderSubSecDivider()}
            </React.Fragment>
        );
    };

    _renderOtherPropDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.editModeStatus}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainForm = () => {
        return (
            <div className="grid pl-2 pr-3">
                <div className="col-9">
                    <form className="splitFrmDiv">
                        {this._renderBasicDetails()}
                        {this._renderSettings()}
                        {this._renderPeripheralsSection()}
                        {this.state.birenabled && this._renderBirSection()}
                    </form>
                </div>
                <div className="col-3">
                    <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                        {this._renderOtherPropDetails()}
                    </div>
                </div>
            </div>
        );
    };

    componentWillReceiveProps = () => {
        const { storeTags, storeDevices, storeTerminals, terminalBIRInfo } =
            this.props.queryData;

        if (storeTags && storeTags.length > 0) {
            var returnedTenders = storeTags.map((e) => ({
                name: e.title,
                id: e.tagtypevalue,
            }));
            this.setState({ storetenders: returnedTenders });

            var birEnabledValue = storeTags
                .filter((e) => e.tagtype === "birinfo")
                .map((e) => e.additionalfields.birenabled);
            this.setState({
                birenabled:
                    birEnabledValue && birEnabledValue.length > 0
                        ? birEnabledValue[0] === "Y"
                        : false,
            });
        } else {
            this.setState({ storetenders: [], birenabled: false });
        }

        if (storeDevices && storeDevices.length > 0) {
            var returnedDevices = storeDevices.map((e) => ({
                name: e.title,
                id: e.deviceid,
            }));
            this.setState({ storedevices: returnedDevices });
        } else {
            this.setState({ storedevices: [] });
        }

        if (!this._isEditMode()) {
            if (storeTerminals && storeTerminals.length > 0) {
                this.setState({ terminaliderror: "Terminal already exist" });
            } else {
                this.setState({ terminaliderror: "" });
            }
        }

        if (terminalBIRInfo && terminalBIRInfo.length > 0) {
            this._updateBIRInfo(terminalBIRInfo[0]);
        }
    };

    componentWillMount = () => {
        //clear if any prev data set
        this.props.queryData.terminalBIRInfo = [];

        const storeid = localStorage.getItem("storeid");
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    brokerid,
                    title,
                    // devicegroup,
                    deviceid,
                    status,
                    devicestatus,
                    provider,
                    additionalfields,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : "";
                        draft.storeid = storeid ? storeid : "";
                        draft.terminalid = terminalid ? terminalid : "";
                        draft.brokerid = brokerid ? brokerid : "";
                        draft.deviceid = deviceid ? deviceid : "";
                        draft.provider = provider ? provider : "";
                        draft.status = status ? status : "";
                        draft.devicestatus = devicestatus ? devicestatus : "";

                        draft.additionalFields = additionalfields;
                        if (additionalfields) {
                            if (additionalfields["customerdisplay"]) {
                                draft.customerdisplay =
                                    additionalfields["customerdisplay"] === "Y";
                            }
                            if (additionalfields["terminaltype"]) {
                                draft.terminaltype =
                                    additionalfields["terminaltype"];
                            }
                            if (additionalfields["posid"]) {
                                draft.posid = additionalfields["posid"];
                            }
                            if (additionalfields["excludedtenders"]) {
                                draft.excludedtenders =
                                    additionalfields["excludedtenders"].split(
                                        ",",
                                    );
                            }
                            if (additionalfields["posperipherals"]) {
                                draft.posperipherals =
                                    additionalfields["posperipherals"].split(
                                        ",",
                                    );
                            }
                            if (additionalfields["virtualprinter"]) {
                                draft.virtualprinter =
                                    additionalfields["virtualprinter"] === "Y";
                            }
                            if (additionalfields["enablemember"]) {
                                draft.enablemember =
                                    additionalfields["enablemember"] === "Y"
                                        ? true
                                        : false;
                            }
                            if (additionalfields["webpos"]) {
                                draft.webpos =
                                    additionalfields["webpos"] === "Y";
                            }
                            if (additionalfields["invallocationid"]) {
                                draft.invallocationid =
                                    additionalfields["invallocationid"];
                            }
                            if (
                                additionalfields["terminalsubtype"] &&
                                additionalfields["terminalsubtype"] === "vpos"
                            ) {
                                draft.vpos = true;
                            }
                            if (
                                additionalfields["printreceipt"] &&
                                additionalfields["printreceipt"] === "Y"
                            ) {
                                draft.printreceipt = true;
                            }
                            if (additionalfields["saleschannel"]) {
                                draft.saleschannel = additionalfields["saleschannel"];
                            }
                        }
                    }),
                );

                this.setState({ isEdit, isView, storeid, terminalid }, () => {
                    this._queryStoreTerminalBIRInfo();
                });
            }
        }

        if (storeid && storeid !== "") {
            this.props.dispatch(
                storeTagsSearchByFields(
                    `taggroup=storeprops&tagtype=tender,birinfo&status=Active&storeid=${storeid}`,
                ),
            );
            this.props.dispatch(
                storeDevicesSearchByFields(
                    `status=Active&storeid=${storeid}&devicegroup=${this.allPeripherals}`,
                ),
            );
        }

        // this.props.dispatch(
        //     queryPropertyConfigByFields(
        //         {
        //             body: {
        //                 propgroup: "SALES_CHANNEL",
        //             },
        //         },
        //         SEARCH_PROP_CONFIG_TENDERTAGTYPES,
        //         SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
        //         SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE,
        //     ),
        // );
    };

    _queryStoreTerminalBIRInfo = () => {
        if (window.config.BIR_ENABLED) {
            if (this._isViewMode() || this._isEditMode()) {
                let queryParam = `storeid=${this.state.storeid}&terminalid=${this.state.terminalid}&taggroup=tprops&tagtype=birinfo`;
                this.props.dispatch(
                    storeTagsSearchByFields(
                        queryParam,
                        CONSTANTS.SEARCH_TERMINAL_BIRINFO_FIELDS_SILENT,
                        CONSTANTS.SEARCH_TERMINAL_BIRINFO_SILENT_SUCCESS,
                        CONSTANTS.SEARCH_TERMINAL_BIRINFO_SILENT_FAILURE,
                    ),
                );
            }
        }
    };

    // convert the date to the right format for sending
    toFormatDate = (dateStr) => {
        const dateandtime = dateStr.split(" ");
        const date = dateandtime[0];

        var parts = date.split("-");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    componentDidUpdate = (prevProps) => {
        const {
            storeTags,
            storeDevices,
            storeTerminals,
            terminalBIRInfo,
            saleChannelTypes,
        } = this.props.queryData;
        const {
            storeTags: prevstoreTags,
            storeDevices: prevstoreDevices,
            storeTerminals: pervstoreTerminals,
            terminalBIRInfo: prevterminalBIRInfo,
            saleChannelTypes: prevtenderTagTypes,
        } = prevProps.queryData;

        if (storeTags !== prevstoreTags) {
            if (storeTags && storeTags.length > 0) {
                var returnedTenders = storeTags
                    .filter((e) => e.tagtype === "tender")
                    .map((e) => ({ name: e.title, id: e.tagtypevalue }));
                this.setState({ storetenders: returnedTenders });

                // check if bir is enabled for this store
                var birEnabledValue = storeTags
                    .filter((e) => e.tagtype === "birinfo")
                    .map((e) => e.additionalfields.birenabled);

                this.setState({
                    birenabled:
                        birEnabledValue && birEnabledValue.length > 0
                            ? birEnabledValue[0] === "Y"
                            : false,
                });
            } else {
                this.setState({ storetenders: [] });
                this.setState({ birenabled: false });
            }
        }

        if (storeDevices !== prevstoreDevices) {
            if (storeDevices && storeDevices.length > 0) {
                var returnedDevices = storeDevices.map((e) => ({
                    name: e.title,
                    id: e.deviceid,
                }));
                this.setState({ storedevices: returnedDevices });
            } else {
                this.setState({ storedevices: [] });
            }
        }

        if (!this._isEditMode()) {
            if (storeTerminals !== pervstoreTerminals) {
                if (storeTerminals && storeTerminals.length > 0) {
                    this.setState({
                        terminaliderror: "Terminal already exist",
                    });
                } else {
                    this.setState({ terminaliderror: "" });
                }
            }
        }

        if (terminalBIRInfo !== prevterminalBIRInfo) {
            if (terminalBIRInfo && terminalBIRInfo.length > 0) {
                this._updateBIRInfo(terminalBIRInfo[0]);
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { createUpdateStoreTerminalSuccess, isSuccess } =
            this.props.crudData;

        let showAlert = createUpdateStoreTerminalSuccess;

        if (createUpdateStoreTerminalSuccess) {
            if (this.pendingbirconfigsave) {
                if (this.state.birid !== undefined && this.state.birid != "") {
                    this.props.dispatch(
                        updateStoreTag(
                            this.getCreatePayloadBIR(),
                            this.state.birid,
                        ),
                    );
                } else {
                    this.props.dispatch(
                        createStoreTag(this.getCreatePayloadBIR()),
                    );
                }

                showAlert = false;
                this.pendingbirconfigsave = false;
            } else {
                showAlert = createUpdateStoreTerminalSuccess || isSuccess;
            }
        }
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="grid pl-3 ml-1">
                        <h2 className="page-header">
                            {this._isEditMode()
                                ? "Edit Terminal"
                                : this._isViewMode()
                                  ? "View Terminal Definition"
                                  : "Add Terminal"}
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col">{this._renderErrorMessage()}</div>
                    </div>
                    {this._renderMainForm()}
                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    <ConfirmAlert
                        show={showAlert}
                        handleClose={this.hideModal}
                        to="/ui/terminals"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode()
                                    ? "Terminal updated successfully"
                                    : "Terminal created successfully"}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const RadioButton = ({ value, label, terminaltype, handleSelection }) => (
    <div className="form-group mr-4 flex">
        <input
            type="radio"
            value={value}
            onChange={handleSelection}
            checked={terminaltype === value}
        />
        <label htmlFor={value} className="floatLeft">
            {label}
        </label>
    </div>
);

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStoreTerminal);
