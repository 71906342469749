import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import {
    queryPropertyConfig,
    searchGeneral,
} from "../../../../actions/actions";
import * as CONSTANTS from "../../../../constants/constants";

const DeviceVedorOptions = (props) => {
    const { devicegroup, handleProviderChange, provider, propConfig } = props;
    const { articleFlowData } = props.queryData;

    const [options, setOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [configTypes, setConfigTypes] = useState({})

    const getValue = (data, key) => {
        const normalizedKey = Object.keys(data).find(
            (k) => k.toLowerCase() === key.toLowerCase(),
        );
        return normalizedKey ? data[normalizedKey] : null; // Return the value or null if not found
    };

    useEffect(() => {
        const selectedConfig = articleFlowData.filter(af => af.propcode == propConfig)
        if (selectedConfig.length > 0) {
            setConfigTypes(selectedConfig[0])
        }
    }, [propConfig, articleFlowData])

    useEffect(() => {
        if (devicegroup.length && configTypes?.additionalfields) {
            const result = getValue(
                configTypes.additionalfields,
                devicegroup,
            );
            setOptions(result || []);
        }
    }, [configTypes, devicegroup]);

    useEffect(() => {
        const result = options.find((op) => op == provider);
        setSelectedItem(result);
    }, [provider, options]);

    useEffect(() => {
        props.dispatch(
            searchGeneral(
                "/cms/v1/prop/config/search",
                createFetchUrl(),
                "SEARCH_ARTICLE_INFO_SUCCESS",
                "SEARCH_ARTICLE_INFO_FAILURE",
            ),
        );
    }, []);

    const createFetchUrl = () => {
        const requestBody = {
            body: {
                language: "en",
                search_field: "status",
                search_condi: "like",
                search_value: "",
            },
        };
        return requestBody;
    };

    const handleChange = (e) => {
        setSelectedItem(e.value);
        handleProviderChange(e.value);
    };

    return (
        <Dropdown
            className="w-full"
            value={selectedItem}
            options={options.map((item) => ({ label: item, value: item }))}
            onChange={handleChange}
            placeholder="Select"
        />
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DeviceVedorOptions);
